import * as React from "react"

const CloudCog = ({ size='80' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 80 80"
  >
    <path
      fill="#fff"
      d="M40.003 63.336c0 1.133.1 2.233.266 3.333h-18.6c-5 0-9.366-1.666-12.966-5.233-3.567-3.5-5.367-7.8-5.367-12.833q0-6.5 3.9-11.6c3.9-5.1 6.1-5.567 10.267-6.5 1.4-5.1 4.166-9.234 8.333-12.4s8.9-4.767 14.167-4.767c6.5 0 12 2.267 16.533 6.8s6.8 10.033 6.8 16.533c3.833.434 7 2.1 9.533 5 .2.167.334.434.467.634-3-1.467-6.4-2.3-10-2.3-12.9 0-23.333 10.433-23.333 23.333m39.433 5.467-3.333 5.766c-.234.434-.667.434-1.1.434l-4.1-1.667c-.9.6-1.8 1.133-2.8 1.567l-.634 4.4c-.066.4-.433.7-.8.7h-6.666c-.467 0-.8-.3-.867-.7l-.633-4.4c-1.034-.4-1.967-.967-2.834-1.567l-4.133 1.667c-.4 0-.833 0-1.033-.434l-3.334-5.766a.87.87 0 0 1 .2-1.067l3.534-2.733a14 14 0 0 1 0-3.334l-3.534-2.733a.83.83 0 0 1-.2-1.067l3.334-5.766c.233-.434.666-.434 1.033-.434l4.133 1.667c.867-.6 1.834-1.133 2.834-1.567l.633-4.4a.87.87 0 0 1 .867-.7h6.666c.367 0 .734.3.767.7l.633 4.4c1.034.4 1.934.967 2.834 1.567l4.1-1.667c.433 0 .866 0 1.066.434l3.334 5.766c.2.367.1.8-.2 1.067l-3.534 2.733c.1.567.134 1.1.134 1.667s-.067 1.1-.134 1.667l3.567 2.733c.3.267.4.7.2 1.067m-11.1-5.467c0-2.767-2.267-5-5-5s-5 2.233-5 5 2.2 5 5 5 5-2.233 5-5"
    ></path>
  </svg>
)

export default CloudCog
