import * as React from "react"

const MoneyBag = ({ size='80' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 80 80"
  >
    <path
      fill="#fff"
      d="M42.753 3.36c.556-1.218 1.593-1.562 2.456-1.6 1.312-.062 2.687.845 3.981 1.913a9.4 9.4 0 0 0 3.294 2.4c.881.313 1.756.375 2.644.332 1.25-.063 2.518-.338 3.775-.4l.068-.007.57-.05c1.437-.068 2.793.27 4.08 1.013 1.182 1.075 1.7 2.412 1.538 4.025q-.411 1.997.706 3.656a27.5 27.5 0 0 0 4.038 2.356c1.462.8 2.38 2.007 2.78 3.613.145 1.7-.643 2.787-2.343 3.256-2.212.556-5.187.556-7.694 2.494-3.662 3.056-4.812 6.137-6.406 6.606-.781-.887-5.656-4.656-13.831-6.462-1.463-5.044-2.35-17.22.344-23.144"
    ></path>
    <path
      fill="#D1D3D4"
      d="M51.016 25.42c1.437-2.876 5.53-7.932 6.512-6.932.969 1.088-1.262 6.738-2.831 9.506-.556-.587-2.35-1.818-3.131-2.024"
    ></path>
    <path
      fill="#748187"
      d="M58.243 34.63a2.5 2.5 0 0 1-2.138-1.207c-4.3-7.075-14.037-6.331-14.131-6.319a2.505 2.505 0 0 1-2.706-2.268 2.504 2.504 0 0 1 2.256-2.713c.525-.05 12.919-1.069 18.856 8.712a2.497 2.497 0 0 1-2.138 3.794"
    ></path>
    <path
      fill="#fff"
      d="M60.051 76.294c-10.706 2.637-25.587 2.45-36.219.35-9.95-1.969-17.568-7.838-16.693-19.388.887-11.687 8.631-22.693 19.281-27.543 10.306-4.694 24.137-6.982 31.425 3.668 1.813 2.65 5.275 8.532 5.438 11.42.012.23.024.437.075.637a2.49 2.49 0 0 0 1.018 1.993c4.581 3.47 7.919 8.07 8.531 14.375.725 7.52-6.387 12.894-12.856 14.488"
    ></path>
    <path
      fill="#1ECECA"
      d="m33.115 58.896-2.593-7.521-2.239-.428-1.297 6.777-4.714-.902 3.946-20.626 8.25 1.578a7.183 7.183 0 0 1 5.72 8.425c-.486 2.534-2.458 4.539-4.946 5.254l2.942 8.413zm-3.028-17.379-1.015 5.304 3.536.677c1.297.248 2.577-.698 2.865-2.201.287-1.503-.554-2.855-1.85-3.103zm21.874 5.285c3.86.738 6.394 4.735 5.554 9.126s-4.67 7.17-8.53 6.432c-1.975-.378-3.29-1.332-4.047-2.638L43.545 67l-4.42-.845 3.946-20.626 4.42.845-.265 1.385c1.187-.934 2.76-1.335 4.735-.957M48.9 58.006c2.033.39 3.771-.714 4.194-2.924s-.786-3.876-2.819-4.265c-2.033-.39-3.771.714-4.194 2.924s.786 3.876 2.819 4.265"
    ></path>
  </svg>
)

export default MoneyBag
