import React, { useContext, useEffect, useState } from 'react'
import { Footer, GooglePlayButton, HeaderB2B } from 'components'
import { Context } from 'helpers/language-provider'
import { FormattedMessage } from 'react-intl'
import { StarRating } from 'common'
import newsData from 'data/news.json'

import { ArrowRight, CloudCog, ElectrumB2B, ElectrumLogo, HandKey, MoneyBag, NetConnection, PiggyBank, ThreeCircles, Thunder } from 'assets/icons'
import { AboutUs, ArrowLeftGradient, ArrowRightGradient, B2BBackground1, B2BTopThumbnail, B2BWallpaperBG, BSS, BSSLocation, BatteryCharge, BatterySwap, DashboardExp, EVBusiness, EVBusinessDark, EVWhyChoose, Elevate, Excellent, ForBusiness, ForBusinessKV, ForBusinessKV2, ForBusinessSide, GetNowPlaceholder, HomeKV, HomeKV2, IntroH3, IntroH3New, IntroH3NewV2, Mileage, PBB, PSB, PackageBG, Placeholder1, Placeholder2, Placeholder3, PlaceholderRectangle1, ProvenQuality, Reviewer1, Reviewer2, Reviewer3, Riders, SaveCost, SepekanKV, SepekanKVBanner, SmartTech, SmartTechB2B, Swaps, TwoWheeler } from 'assets/images'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import { HematSimulation, EcosystemText } from 'assets/lottie'
import Marquee from 'react-fast-marquee'
import { useNavigate } from 'react-router-dom'
import CostCalculator from 'components/cost-calculator-desktop'

// const banner1Placeholder = 'https://res.cloudinary.com/aldrichn/image/upload/v1713433322/Frame_490_r3sduk.png'
const banner1Placeholder = 'https://i.postimg.cc/fRNsGnYs/Frame-811270.png'
const banner2Placeholder = 'https://i.postimg.cc/9FXtJznH/Frame-505.png'
const asset1Placeholder = 'https://i.postimg.cc/vmx50j79/Frame-811279.png'
const asset2Placeholder = 'https://i.postimg.cc/5t8mmyML/Frame-710.png'
const designedPlaceholder = 'https://i.postimg.cc/JhGJhJX5/Frame-811279.png'

const HomeNew = () => {
  const { locale } = useContext(Context)
  const defaults = require('languages/id.json')
  const navigate = useNavigate()

  // BUTTON STYLES
  const btnStyleBlue = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#0B4571',
    border: 'none',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
  }
  const btnStyleWhite = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    border: 'none',
    color: '#0B4571',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
    width: 'fit-content',
  }
  const btnStyleTransparent = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: '3px solid white',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
  }

  // COLORED TEXT
  const ColoredText = styled.span`
    background: linear-gradient(to right, #158A9D, #1ECECA);
    color: transparent;
    background-clip: text;
  `

  return (
    <div style={{
      backgroundColor: '#F4F7FA',
    }}>
      <HeaderB2B />

      {/* TOP BANNER */}
      <div style={{
        padding: '8.5rem 10.5rem 8.5rem 10.5rem',
        backgroundImage: `url(${B2BBackground1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '70vh',
        height: '100%',
        position: 'relative',
        zIndex: 1,
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '1.25rem',
          backgroundColor: 'rgba(53, 150, 204, 0.2)',
          backdropFilter: 'blur(0.5rem)',
          position: 'relative',
          padding: '5rem 3.5rem',
          width: '80%',
          zIndex: 1,
        }}>
          <div style={{ zIndex: 2 }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '3.5rem', color: 'white', marginBottom: '1.5rem' }}>
              Electrum For <br /> Business
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.25rem', color: 'white' }}>
              Empowering your fleet with reliable <br /> performance, efficiency, and sustainable <br /> growth.
            </div>
          </div>
          <img src={B2BTopThumbnail} alt="b2b-thumbnail"
            style={{
              width: '30rem',
              position: 'absolute',
              right: '-7.5%',
              top: '-11%',
              zIndex: 3,
            }}
          />
        </div>
        <img src={ArrowRightGradient} alt='arrow-gradient'
          style={{
            height: '3.5rem',
            position: 'absolute',
            bottom: '7.5rem',
            zIndex: 2,
          }}
        />
      </div>

      {/* FOR BUSINESS */}
      <div style={{
        backgroundColor: '#0F212D',
        padding: '7.5rem 0rem 20.5rem 0rem',
        borderRadius: '3.5rem',
        position: 'relative',
        marginTop: '-3.5rem',
        zIndex: 2,
      }}>
        <div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.5rem', color: 'white', marginBottom: '1rem' }}>
              Electrum for Business
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#AEC7E2', margin: '1rem 0rem 5rem 0rem' }}>
              <b>Electrum for Business</b> menawarkan opsi sewa atau beli kendaraan listrik roda dua yang <br/> fleksibel untuk operasional bisnis Anda sekaligus mendukung keberlanjutan lingkungan.
            </div>
          </div>
          <img alt='ev-business' src={EVBusinessDark} style={{ width: '100%' }} />
        </div>
      </div>

      {/* ELEVATE */}
      <div style={{
        margin: '-20.5rem 12.5rem 0rem 12.5rem',
        borderRadius: '1.25rem',
        position: 'relative',
        zIndex: 3,
      }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2.5rem' }}>
          <img src={ArrowLeftGradient} alt='arrow-gradient' style={{ height: '3.5rem' }} />
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <div style={{ padding: '5rem 3.25rem', backgroundColor: '#103856', borderRadius: '1.25rem 0 0 1.25rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3rem', color: 'white', marginBottom: '2.5rem' }}>
              Elevate Your Business with Electrum
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.375rem', color: '#AEC7E2' }}>
              Built for durability and dependable performance, even in demanding operations.
            </div>
          </div>
          <div style={{ backgroundColor: '#103856', borderRadius: '0 1.25rem 1.25rem 0' }}>
            <img loading='lazy' alt='smart-tech' src={Elevate} style={{ width: '100%', height: '100%', borderRadius: '1.25rem' }} />
          </div>
        </div>
        <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          columnGap: '1.25rem',
          marginTop: '-2rem',
        }}>
          <div style={{
            padding: '2rem',
            borderRadius: '1rem',
            backgroundColor: 'rgba(18, 91, 146, 0.5)',
            backdropFilter: 'blur(1rem)',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
          }}>
            <HandKey />
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white' }}>
              Flexible Ownership Options
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#D9E7F5' }}>
              Buy or lease Electrum Bikes to suit your business needs.
            </div>
          </div>
          <div style={{
            padding: '2rem',
            borderRadius: '1rem',
            backgroundColor: 'rgba(18, 91, 146, 0.5)',
            backdropFilter: 'blur(1rem)',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
          }}>
            <ThreeCircles />
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white' }}>
              Seamless Fleet Conversion
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#D9E7F5' }}>
              Transition to EV solutions with expert guidance and ease.
            </div>
          </div>
          <div style={{
            padding: '2rem',
            borderRadius: '1rem',
            backgroundColor: 'rgba(18, 91, 146, 0.5)',
            backdropFilter: 'blur(1rem)',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
          }}>
            <NetConnection />
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white' }}>
              Comprehensive Support
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#D9E7F5' }}>
              Gain access to Electrum's advanced Digital and Energy Infrastructure.
            </div>
          </div>
        </div>
      </div>

      {/* REST OF THE COMPONENT */}
      <div style={{
        padding: '62.5rem 6.5rem 10.5rem 6.5rem',
        backgroundImage: `url(${B2BWallpaperBG})`,
        backgroundSize: 'cover',
        marginTop: '-50rem',
      }}>
        {/* WHY CHOOSE ELECTRUM */}
        <div style={{ marginLeft: '5rem', marginBottom: '1.5rem' }}>
          <img src={ArrowRightGradient} alt='arrow-gradient' style={{ height: '3.5rem' }} />
        </div>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.5rem', color: 'white', textAlign: 'center', marginBottom: '2rem' }}>
          Why Choose Electrum
        </div>
        <img src={EVWhyChoose} alt='why-choose' style={{ width: '100%', borderRadius: '2.5rem' }} />
        <div style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          columnGap: '1.25rem',
          margin: '-2.5rem 2.5rem 0 2.5rem',
          padding: '5rem 3.5rem 3.5rem 3.5rem',
          backgroundColor: 'rgba(18, 91, 146, 0.4)',
          borderRadius: '1.25rem',
        }}>
          <div style={{
            padding: '2rem',
            borderRadius: '1rem',
            backgroundColor: 'rgba(18, 91, 146, 0.3)',
            backdropFilter: 'blur(1rem)',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
          }}>
            <PiggyBank size='50' />
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white' }}>
              Save up to 40%
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#D9E7F5' }}>
              Built for durability and dependable performance, even in demanding operations.
            </div>
          </div>
          <div style={{
            padding: '2rem',
            borderRadius: '1rem',
            backgroundColor: 'rgba(18, 91, 146, 0.3)',
            backdropFilter: 'blur(1rem)',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
          }}>
            <MoneyBag size='50' />
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white' }}>
              Boost Revenue
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#D9E7F5' }}>
              Improve profitability by maximizing fleet efficiency and minimizing downtime.
            </div>
          </div>
          <div style={{
            padding: '2rem',
            borderRadius: '1rem',
            backgroundColor: 'rgba(18, 91, 146, 0.3)',
            backdropFilter: 'blur(1rem)',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
          }}>
            <CloudCog size='50' />
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white' }}>
              Real-Time Control
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#D9E7F5' }}>
              Utilize IoT technology for full visibility, proactive monitoring, and asset management.
            </div>
          </div>
        </div>
        {/* DASHBOARD EXP */}
        <div style={{ marginTop: '12.5rem' }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2.5rem', marginRight: '3rem' }}>
            <img src={ArrowLeftGradient} alt='arrow-gradient' style={{ height: '3.5rem' }} />
          </div>
          <div style={{
            backgroundImage: `url(${DashboardExp})`,
            backgroundSize: 'cover',
            paddingTop: '4.5rem',
            minHeight: '95vh',
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.5rem', color: 'white', textAlign: 'center', marginBottom: '1rem' }}>
                Excellent Dashboard <br/> Experience with Electrum
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#AEC7E2', textAlign: 'center' }}>
                <b>Electrum for Business</b> menawarkan opsi sewa atau beli kendaraan listrik roda dua <br/> yang fleksibel untuk operasional bisnis Anda sekaligus mendukung keberlanjutan lingkungan.
              </div>
              <div style={{ display: 'flex', columnGap: '1rem', marginTop: '1.5rem' }}>
                <button
                  style={btnStyleBlue}
                  onClick={() => navigate('/get-yours')}
                >
                  CTA 1 <ArrowRight fill="#fff" />
                </button>
                <button
                  style={btnStyleWhite}
                  onClick={() => navigate('/products/h3i')}
                >
                  CTA 2 <ArrowRight fill="#0B4571" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer
        backgroundColor='#2E637E'
      />
    </div>
  )
}

export default HomeNew
