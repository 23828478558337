import React, { useContext, useEffect, useRef, useState } from 'react'
import { Context } from 'helpers/language-provider'

import { ArrowRight, IonLeaf, Motorcycle, Thunder, Verified } from 'assets/icons'
import { AboutTD1, AboutTD2, AboutTD3, AboutTD4, BSS, BatteryCharge, BatterySwap, ColorAmber, ColorBeige, ColorDerawanBlue, ColorGrey, ColorIceNavy, ColorIvoryWhite, ColorMidnightBromo, ColorRedCherry, ColorViridianGreen, DuoH3, EfficientPlaceholder, Feature, GetNowPlaceholder, H3Background, IntroProduct, PBB, PSB, PackageBG, Placeholder2, ProductEV, ProductKV, ProductKVH3, ProductLarge, ProductSmall, RectanglePlaceholder, TechDesign1, TechDesign2, TechDesign3, Understand1, Understand2, Understand3, Understand4, Understand5, Understand6 } from 'assets/images'
import { ElectrumMilestone } from 'assets/videos'
import styled from 'styled-components'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { Input } from 'antd'

const bannerPlaceholder = 'https://i.postimg.cc/fRNsGnYs/Frame-811270.png'

const { TextArea } = Input

const ProductH3 = () => {
  const { locale } = useContext(Context)
  const defaults = require('languages/id.json')
  const navigate = useNavigate()

  // BUTTON STYLES
  const btnStyleBlue = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#0B4571',
    border: 'none',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
  }
  const btnStyleWhite = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: '2px solid white',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
  }

  // KV BANNER
  const [currentKVIndex, setCurrentKVIndex] = useState(0)
  const bannerList = [
    {
      banner_img: ProductKV,
      headline: 'Electrum H3',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Pre-order Now',
      button1_link: '',
      button2_text: 'Rent Now',
      button2_link: '',
    },
    {
      banner_img: ProductKV,
      headline: 'Electrum H3',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Pre-order Now',
      button1_link: '',
      button2_text: 'Rent Now',
      button2_link: '',
    },
    {
      banner_img: ProductKV,
      headline: 'Electrum H3',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Pre-order Now',
      button1_link: '',
      button2_text: 'Rent Now',
      button2_link: '',
    },
    {
      banner_img: ProductKV,
      headline: 'Electrum H3',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Pre-order Now',
      button1_link: '',
      button2_text: 'Rent Now',
      button2_link: '',
    },
    {
      banner_img: ProductKV,
      headline: 'Electrum H3',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Pre-order Now',
      button1_link: '',
      button2_text: 'Rent Now',
      button2_link: '',
    },
  ]
  const renderKVCarousel = () => {
    return bannerList.map((item, index) => (
      <div key={index}>
        <div
          alt="Banner Placeholder"
          style={{
            backgroundImage: `url('${item.banner_img}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '100vw',
            height: '100%',
            scrollSnapAlign: 'start',
          }}
        >
          <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '6.5rem' }}>
            {/* HEADLINE & SUBHEADLINE */}
            <div style={{ fontFamily: 'Gilroy-Bold', color: 'white', fontSize: '4.475rem' }}>
              {item.headline}
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', color: 'white', fontSize: '2.5rem' }}>
              {item.subheadline}
            </div>
            {/* BUTTONS */}
            <div style={{ display: 'flex', columnGap: '1rem', marginTop: '1.5rem' }}>
              <button style={btnStyleBlue}>
                {item.button1_text} <ArrowRight fill="#fff" />
              </button>
              <button style={btnStyleWhite}>
                {item.button2_text} <ArrowRight fill="#000" />
              </button>
            </div>
          </div>
        </div>
      </div>
    ))
  }
  const handleKVCarouselClick = (index) => {
    setCurrentKVIndex(index)
    const carousel = document.getElementById('kv-carousel')
    carousel.scrollTo({ left: index * window.innerWidth, behavior: 'smooth' })
  }
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const nextIndex = (currentKVIndex + 1) % bannerList.length
  //     setCurrentKVIndex(nextIndex)
  //     const carousel = document.getElementById('kv-carousel')
  //     carousel.scrollTo({ left: nextIndex * window.innerWidth, behavior: 'smooth' })
  //   }, 3500)
  //   return () => clearInterval(interval)
  // }, [currentKVIndex, bannerList.length])

  // COLOR BANNER
  const [currentColorIndex, setCurrentColorIndex] = useState(0)
  const colorList = [
    // { img: ColorIvoryWhite, color: 'Ivory White', palette: '#e5e5e5' },
    // { img: ColorRedCherry, color: 'Red Cherry', palette: '#f04c41' },
    // { img: ColorAmber, color: 'Amber', palette: '#f49f25' },
    // { img: ColorIceNavy, color: 'Ice Navy', palette: '#a7d3dd' },
    // { img: ColorViridianGreen, color: 'Viridian Green', palette: '#3f7772' },
    // { img: ColorBeige, color: 'Beige', palette: '#d5cdc2' },
    // { img: ColorGrey, color: 'Grey', palette: '#808388' },
    { img: ColorDerawanBlue, color: 'Derawan Blue', palette: '#40C0C0' },
    { img: ColorMidnightBromo, color: 'Midnight Bromo', palette: '#0B4571' },
  ]
  const renderColorCarousel = () => {
    return colorList.map((item, index) => (
      <div key={index}>
        <img loading='lazy'  alt={item.color} src={item.img} style={{ width: '100vw', marginBottom: '3rem', scrollSnapAlign: 'start' }} />
      </div>
    ))
  }
  const renderColorBar = () => {
    return colorList.map((item, index) => (
      <div style={currentColorIndex === index ? {
        display: 'inline-block',
        padding: '0.25rem',
        boxShadow: `0px 0px 10px 0px ${item.palette}`,
        borderRadius: '20rem',
      } : {
        padding: '0rem',
        boxShadow: 'none',
      }}>
        <div
          style={{ cursor: 'pointer', width: '5.5rem', height: '0.65rem', borderRadius: '20rem', backgroundColor: item.palette }}
          onClick={() => handleColorCarouselClick(index)}
        />
      </div>
    ))
  }
  const handleScrollColorCarousel = (direction) => {
    const container = document.getElementById('color-carousel')
    const containerWidth = container.clientWidth
    container.scrollTo({
      left: container.scrollLeft + direction * containerWidth,
      behavior: 'smooth',
    })
  }
  const handleColorCarouselClick = (index) => {
    setCurrentKVIndex(index)
    const carousel = document.getElementById('color-carousel')
    carousel.scrollTo({ left: index * window.innerWidth, behavior: 'smooth' })
  }

  // ABOUT T-D
  const containerStyle = {
    backgroundColor: 'white',
    padding: '6.25rem 12.5rem 3.5rem 12.5rem',
    borderRadius: '1.125rem',
  }
  const gridContainerStyle = {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '2.5rem',
    marginTop: '2.5rem',
    // gridTemplateAreas: `
    //   "header header"
    //   "content1 content2"
    //   "content3 content4"
    // `,
    position: 'relative',
  }
  const titleStyle = {
    fontFamily: 'Gilroy-ExtraBold',
    fontSize: '3rem',
    color: '#103856',
    marginBottom: '2rem',
    gridColumn: '1 / -1',
  }
  const gridItemStyle = (index) => ({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
    marginBottom: '4rem',
    position: 'relative',
    zIndex: index % 2 === 0 ? 2 : 1,
    marginTop: index % 2 !== 0 ? '-9rem' : '0',
  })
  const imageStyle = {
    width: '100%',
  }
  const headerStyle = {
    fontFamily: 'Gilroy-Bold',
    fontSize: '1.5rem',
    color: '#103856',
  }
  const subheaderStyle = {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '1rem',
    color: '#71869C',
    marginTop: '-0.25rem',
  }
  const tdList = [
    {
      img: AboutTD1,
      header: 'Bagasi Luas',
      subheader: 'Nggak ada yang bisa ngalahin luasnya bagasi motor listrik Electrum H3. Mulai dari tas, topi, sepatu, jas hujan, sampai helm full face, semua muat!',
    },
    {
      img: AboutTD2,
      header: 'Desain Modern',
      subheader: 'Dibawa ngantor, morning ride, atau hangout, Electrum H3 bakal jadi fashion statement favorit kamu.',
    },
    {
      img: AboutTD3,
      header: 'Ruang Lega',
      subheader: 'Area motornya yang lega, mulai dari ruang kaki sampai jok, bikin sensasi berkendara lebih nyaman.',
    },
    {
      img: AboutTD4,
      header: 'Lincah & Ringan',
      subheader: 'Bobotnya yang ringan bikin Electrum H3 jadi paling sat-set dan lincah di jalan. Jalanan berbelok-belok? Nggak masalah!',
    },
  ]
  const renderTD = () => {
    return tdList.map((item, index) => (
      <div ley={index} style={gridItemStyle(index % 2 !== 0)}>
        <img loading='lazy'  alt={`td${index+1}`} src={item.img} style={imageStyle} />
        <div style={headerStyle}>{item.header}</div>
        <div style={subheaderStyle}>{item.subheader}</div>
      </div>
    ))
  }

  // COLORED TEXT
  const ColoredText = styled.span`
    background: linear-gradient(0deg, #0B4571, #1ECECA);
    color: transparent;
    background-clip: text;
    font-family: Gilroy-Bold;
    font-size: 2rem;
  `

  // UNDERSTAND
  const understandArr = [
    {
      img: Understand1,
      title: 'Baterai Tahan Lama',
      description: 'Berbekal 1 buah baterai NCM yang tahan jarak tempuh hingga 65 km, riding ke tempat hangout after-office lebih dari cukup.',
    },
    {
      img: Understand2,
      title: '2 Mode Unggulan',
      description: '<b>Mode Sport</b> siap menjawab kebutuhan akselerasi dan kecepatan, sedangkan <b>Mode Smart</b> bantu kamu hemat energi tanpa mengurangi performa.',
    },
    {
      img: Understand3,
      title: 'Cruise Control & Reverse Assist',
      description: 'Fitur <b>Cruise Control</b> memungkinkan kamu aman berkendara dengan kecepatan konstan. Butuh bantuan mundur? Serahkan pada fitur <b>Reverse Assist</b>.',
    },
    {
      img: Understand4,
      title: 'Smart Key Remote',
      description: 'Dengan <b>Smart Key Remote</b>, kamu bisa menyalakan/mematikan motor, tanda alarm, hingga membuka jok. Semua kebutuhan dalam satu genggaman.',
    },
    {
      img: Understand5,
      title: 'Adaptive Speedometer',
      description: 'Tidak hanya menampilkan informasi performa motor dan baterai, warna panel speedometer dapat berubah secara adaptif mengikuti terang-gelapnya kondisi sekeliling saat berkendara.',
    },
    {
      img: Understand6,
      title: 'Pengisi daya USB',
      description: 'Nggak ada lagi cerita handphone-mu lowbat. Charge aja langsung lewat USB port di motor listrik Electrum kamu. Anti lowbat-lowbat club!',
    },
  ]
  const renderUnderstand = () => {
    return understandArr.map((item, index) => (
      <div key={index} style={{ flexShrink: 0, maxWidth: '19rem' }}>
      <img loading='lazy'  alt='understand' src={item.img} style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }} />
        <div style={{ backgroundColor: 'white', borderRadius: '0 0 0.625rem 0.625rem', padding: '2rem 1.5rem', minHeight: '10.5rem', marginTop: '-0.5rem' }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', color: '#103856', marginBottom: '0.65rem' }}>
            {item.title}
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C' }}>
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
          </div>
        </div>
      </div>
    ))
  }

  // SPECIFICATIONS
  const specsDetail1 = [
    { label: 'Dimensi', value: '1.900 x 770 x 1.020 mm' },
    { label: 'Kecepatan Maks.', value: '70 Km/h' },
    { label: 'Berat', value: '87 kg' },
    { label: 'Output Tenaga Maks.', value: '2.7 KW' },
    { label: 'Kapasitas Baterai', value: '1 x 72v25Ah \n 1.8 KwH' },
  ]
  const specsDetail2 = [
    { label: 'Torsi Maks.', value: '150 Nm' },
    { label: 'Jarak Tempuh Maks.', value: '65 Km' },
    { label: 'Sistem Pengereman', value: 'Cakram Ganda' },
  ]

  return (
    <div style={{ backgroundColor: '#F4F7FA' }}>

      {/* BANNER CAROUSEL */}
      {/* <div>
        <div
          id="kv-carousel"
          style={{
            width: '100vw',
            height: '90vh',
            margin: '0 auto',
            backgroundColor: 'white',
            display: 'flex',
            overflowX: 'scroll',
            scrollSnapType: 'x mandatory',
            position: 'relative',
          }}
          onScroll={(e) => {
            const scrollPosition = e.target.scrollLeft
            const newIndex = Math.round(scrollPosition / window.innerWidth)
            setCurrentKVIndex(newIndex)
          }}
        >
          {renderKVCarousel()}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '-3rem',
            position: 'relative',
            zIndex: '99',
          }}
        >
          {bannerList.map((slide, index) => (
            <div
              key={index}
              onClick={() => handleKVCarouselClick(index)}
              style={{
                width: '45px',
                height: '6.5px',
                borderRadius: '50rem',
                backgroundColor: currentKVIndex === index ? '#1ECECA' : '#4F939D',
                margin: '0 5px',
                cursor: 'pointer',
              }}
            ></div>
          ))}
        </div>
      </div> */}

      {/* SINGLE BANNER */}
      <div
        alt="Banner Placeholder"
        style={{
          backgroundImage: `url('${ProductKVH3}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          height: '90vh',
          scrollSnapAlign: 'start',
        }}
      >
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '6.5rem' }}>
          <div style={{ fontFamily: 'Gilroy-Bold', color: 'white', fontSize: '4.475rem' }}>
            Electrum H3
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', color: 'white', fontSize: '2.5rem' }}>
            Lincah di Jalan dengan Bobot Ringan
          </div>
          {/* <div style={{ display: 'flex', columnGap: '1rem', marginTop: '1.5rem' }}>
            <button style={btnStyleBlue} onClick={() => navigate('/get-yours')}>
              Dapatkan Sekarang <ArrowRight fill="#fff" />
            </button>
            <button style={btnStyleWhite} onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}>
              Daftar Test-ride <ArrowRight fill="#fff" />
            </button>
          </div> */}
        </div>
      </div>

      {/* VIDEO BANNER */}
      {/* <div style={{ width: '100vw', height: '90vh', backgroundColor: '#0F212D', position: 'relative' }}>
        <div class="loader-container">
          <div class="bouncing-dots">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
        <video
          src={ElectrumMilestone}
          autoPlay
          muted
          loop
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'relative',
            zIndex: '1',
          }}
        />
      </div> */}

      {/* INTRO H3 V2 */}
      <div style={{ padding: '10rem 0rem 2.5rem 10rem' }}>
        <div style={{
          backgroundImage: `url('${H3Background}')`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          padding: '3rem 5rem',
          borderRadius: '2.5rem 0 0 2.5rem',
        }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: '#103856', marginBottom: '1rem' }}>
            Motor Listrik Jagoan <br/> Bisnis Harian
          </div>
          <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1.125rem', color: '#103856', marginBottom: '1.5rem' }}>
            <b>Electrum H3</b> adalah pilihan tepat untuk Anda yang <br/> mencari motor listrik hemat energi, fleksibel, dan dapat <br/> diandalkan untuk operasional harian.
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1rem',
            maxWidth: '45%',
          }}>
            <div style={{ backgroundColor: 'rgba(173, 240, 255, 0.4)', backdropFilter: 'blur(0.5rem)', borderRadius: '1.25rem', padding: '1.5rem 2rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', columnGap: '2rem' }}>
                <div>
                  <IonLeaf size='50' />
                </div>
                <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#103856' }}>
                  Konsumsi daya rendah, lebih ekonomis untuk bisnis Anda.
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: 'rgba(173, 240, 255, 0.4)', backdropFilter: 'blur(0.5rem)', borderRadius: '1.25rem', padding: '1.5rem 2rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', columnGap: '2rem' }}>
                <div>
                  <Motorcycle size='55' />
                </div>
                <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#103856' }}>
                  Desain ramping memudahkan driver untuk bermanuver tanpa cepat lelah.
                </div>
              </div>
            </div>
            <div style={{ backgroundColor: 'rgba(173, 240, 255, 0.4)', backdropFilter: 'blur(0.5rem)', borderRadius: '1.25rem', padding: '1.5rem 2rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', columnGap: '2rem' }}>
                <div>
                  <Verified size='50' />
                </div>
                <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#103856' }}>
                  Dipercaya oleh lebih dari 3.000 pengendara, dengan total perjalanan lebih dari 20.000.000 kilometer.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* BATTERY LOCATION */}
      <div style={{ padding: '10rem 12.5rem 2.5rem 12.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: '#103856', textAlign: 'center' }}>
          Lokasi Baterai yang Tersebar <br />
          di Jadetabek
        </div>
        <div style={{ margin: '1rem 0rem', display: 'flex', justifyContent: 'center' }}>
          <img loading='lazy'  alt='bss' src={BSS} style={{ width: '100%' }} />
        </div>
      </div>

      {/* CHOOSE COLOR */}
      <div style={{ padding: '10rem 0rem 5rem 0rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.75rem', color: '#103856', marginBottom: '3.125rem', textAlign: 'center' }}>
          Koleksi Warna <span style={{ color: '#1ECECA' }}>Electrum H3</span>
        </div>
        {/* IMG PREVIEW */}
        <div style={{ position: 'relative', width: '100%' }}>
          {/* PREV BUTTON */}
          <button
            onClick={() => handleScrollColorCarousel(-1)}
            style={{
              position: 'absolute',
              top: '43.5%',
              left: '5rem',
              transform: 'translateY(-43.5%)',
              backgroundColor: 'white',
              color: 'black',
              border: 'none',
              borderRadius: '50%',
              padding: '0.5rem 0.625rem',
              cursor: 'pointer',
              fontSize: '1.5rem',
              display: currentColorIndex === 0 ? 'none' : 'block',
              zIndex: 1,
            }}
          >
            <LeftOutlined style={{ color: '#1ECECA' }} />
          </button>
          {/* SCROLLABLE CAROUSEL */}
          <div
            id="color-carousel"
            style={{
              width: '100vw',
              margin: '0 auto',
              display: 'flex',
              overflowX: 'scroll',
              scrollSnapType: 'x mandatory',
            }}
            onScroll={(e) => {
              const scrollPosition = e.target.scrollLeft;
              const newIndex = Math.round(scrollPosition / window.innerWidth);
              setCurrentColorIndex(newIndex);
            }}
          >
            {renderColorCarousel()}
          </div>
          {/* NEXT BUTTON */}
          <button
            onClick={() => handleScrollColorCarousel(1)}
            style={{
              position: 'absolute',
              top: '43.5%',
              right: '5rem',
              transform: 'translateY(-43.5%)',
              backgroundColor: 'white',
              color: 'black',
              border: 'none',
              borderRadius: '50%',
              padding: '0.5rem 0.625rem',
              cursor: 'pointer',
              fontSize: '1.5rem',
              display: currentColorIndex === colorList.length - 1 ? 'none' : 'block',
              zIndex: 1,
            }}
          >
            <RightOutlined style={{ color: '#1ECECA' }} />
          </button>
        </div>
        {/* COLOR SELECTOR */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '3.75rem' }}>
          <div style={{
            padding: '2rem',
            display: 'flex',
            alignItems: 'center',
            columnGap: '1.5rem',
            boxShadow: '0px 4px 20px 0px rgba(0,0,0,0.1)',
            width: 'fit-content',
            borderRadius: '1.25rem',
            marginBottom: '1.25rem',
            backgroundColor: 'white',
          }}>
            {renderColorBar()}
          </div>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem' }}>
            {colorList[currentColorIndex].color}
          </div>
        </div>
        {/* SPECIFICATION */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{
            backgroundColor: '#FFFFFF',
            padding: '3rem',
            borderRadius: '1.25rem',
            display: 'flex',
            columnGap: '3.25rem',
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                70 km/jam
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856' }}>
                Kecepatan Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                1.8 KwH
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856' }}>
                Kapasitas Baterai
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                65 km
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856' }}>
                Jarak Tempuh Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                150 Nm
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856' }}>
                Torsi Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                2.7 kW
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856' }}>
                Output Tenaga Maks.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SPECS DETAIL */}
      <div style={{ paddingBottom: '10rem' }}>
        <div style={{
          backgroundColor: 'white',
          padding: '5rem 7rem',
          borderRadius: '4rem',
        }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '2.5rem' }}>
            Electrum H3
          </div>
          <div style={{ height: '0.625rem', width: '11rem', backgroundColor: '#F04C3E', margin: '2rem 0rem 3.75rem 0rem' }} />
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '5.5rem' }}>
            {/* 1ST SECT */}
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
              {specsDetail1.map((spec, index) => (
                <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856' }}>
                    {spec.label}
                  </div>
                  <div
                    style={{ fontFamily: 'Gilroy-Medium', fontSize: '1.25rem', color: '#103856', textAlign: 'right', lineHeight: '2rem' }}
                    dangerouslySetInnerHTML={{ __html: spec.value.replace(/\n/g, "<br>") }}
                  />
                </div>
              ))}
            </div>
            {/* 2ND SECT */}
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
              {specsDetail2.map((spec, index) => (
                <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856' }}>
                    {spec.label}
                  </div>
                  <div
                    style={{ fontFamily: 'Gilroy-Medium', fontSize: '1.25rem', color: '#103856', textAlign: 'right', lineHeight: '2rem' }}
                    dangerouslySetInnerHTML={{ __html: spec.value.replace(/\n/g, "<br>") }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* H3 FORM */}
      <div style={{ padding: '0rem 12.5rem 12.5rem 0rem' }}>
        <div style={{
          backgroundColor: '#103856',
          borderRadius: '0 3.125rem 3.125rem 0',
          padding: '5.5rem 5.5rem 5.5rem 12.5rem',
          display: 'grid',
          gridTemplateColumns: '1.125fr 1fr',
          columnGap: '3.5rem',
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.8rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3rem', color: 'white' }}>
              Solusi Kendaraan Listrik untuk Kemajuan Bisnis Anda
            </div>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.125rem', color: '#AEC7E2' }}>
              Ingin operasional bisnis lebih efisien dengan kendaraan listrik yang andal dan hemat energi?
              <br /><br />
              Hubungi kami sekarang dan temukan solusi terbaik untuk kebutuhan bisnis Anda.
            </div>
          </div>
          <div style={{ backgroundColor: 'white', padding: '1.5rem', borderRadius: '1.25rem', height: 'fit-content' }}>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Nama Lengkap
              </div>
              <Input name='fullName' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Nomor Telepon
              </div>
              <Input name='phoneNumber' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Alamat Email
              </div>
              <Input name='email' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Pesan
              </div>
              <TextArea rows={3} name='message' />
            </div>
            <div>
              <button
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '0.875rem',
                  color: 'white',
                  backgroundColor: '#0B4571',
                  border: '2px solid #0B4571',
                  padding: '0.5rem 0.85rem',
                  borderRadius: '0.375rem',
                  marginBottom: 'initial',
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* GET NOW */}
      {/* <div style={{ padding: '5rem 0rem 7.5rem 0rem' }}>
        <div style={{ width: '85%', position: 'relative' }}>
          <img loading='lazy'  alt='getnow-placeholder' src={GetNowPlaceholder} style={{ width: '100%' }} />
          <div style={{
            width: '35%',
            padding: '3.5rem 5rem 3.5rem 8.5rem',
            backgroundColor: 'white',
            borderRadius: '0 1.125rem 1.125rem 0',
            position: 'absolute',
            top: '80%',
            left: '0',
            transform: 'translateY(-80%)',
          }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.875rem', marginBottom: '1rem' }}>
              Dapatkan Sekarang!
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', marginBottom: '3.5rem' }}>
              Tersedia pilihan paket Electrum yang menarik melalui variasi metode pembayaran yang kamu inginkan.
            </div>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
                width: 'fit-content',
              }}
              onClick={() => navigate('/get-yours')}
            >
              Dapatkan H3 <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div> */}

    </div>
  )
}

export default ProductH3
