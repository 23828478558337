import * as React from "react"

const NetConnection = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="45"
    fill="none"
    viewBox="0 0 55 55"
  >
    <mask
      id="mask0_8044_914"
      width="50"
      height="50"
      x="2"
      y="3"
      maskUnits="userSpaceOnUse"
      style={{ maskType: "luminance" }}
    >
      <path
        fill="#555"
        fillRule="evenodd"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.491"
        d="M9.155 14.255a4.49 4.49 0 1 0 0-8.98 4.49 4.49 0 0 0 0 8.98m2.246 33.682a6.736 6.736 0 1 0 0-13.472 6.736 6.736 0 0 0 0 13.472m31.436 2.246a6.737 6.737 0 1 0 0-13.473 6.737 6.737 0 0 0 0 13.473M24.873 32.219a8.982 8.982 0 1 0 0-17.963 8.982 8.982 0 0 0 0 17.963m13.473-17.964a4.49 4.49 0 1 0 0-8.981 4.49 4.49 0 0 0 0 8.981"
        clipRule="evenodd"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.491"
        d="m12.523 13.133 4.491 4.49m16.841-3.368-2.245 2.246m6.736 21.893-6.736-8.42m-15.718 5.613 4.49-4.49"
      ></path>
    </mask>
    <g mask="url(#mask0_8044_914)">
      <path fill="#fff" d="M.156.781h53.892v53.892H.155z"></path>
    </g>
  </svg>
)

export default NetConnection
