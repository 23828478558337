import React, { useContext, useEffect, useState } from 'react'
import { Modal, StarRating } from 'common'
import { Collapse } from 'antd'
import { Context } from 'helpers/language-provider'
import { FormattedMessage } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons'
import newsData from 'data/news.json'

import { ArrowRight, ArrowRightOutline, CloudCog, ElectrumB2B, ElectrumBlue, HandKey, MoneyBag, NetConnection, PiggyBank, ThreeCircles } from 'assets/icons'
import {
  AboutUs,
  ArrowLeftGradient,
  ArrowRightGradient,
  B2BBackground1Mobile,
  B2BTopThumbnail,
  B2BWallpaperBG,
  BSS,
  BSSLocation,
  BatteryCharge,
  BatterySwap,
  DashboardExp,
  DashboardExpMobile,
  EVBusinessDark,
  EVBusinessMobile,
  EVWhyChoose,
  Elevate,
  ElevateMobile,
  ForBusinessKV2Mobile,
  GetNowPlaceholderMobile,
  HomeKVMobile,
  HomeKVMobile2,
  IntroH3,
  IntroH3New,
  IntroH3NewV2,
  Mileage,
  MobileKV,
  PBB,
  PSB,
  PackageBG,
  ProvenQuality,
  Reviewer1,
  Reviewer2,
  Reviewer3,
  Riders,
  SepekanKV,
  SepekanKVBanner,
  SepekanKVMobile,
  SmartTech,
  Swaps,
} from 'assets/images'
import Lottie from 'react-lottie'
import { EcosystemTextMobile, HematSimulation } from 'assets/lottie'
import styled from 'styled-components'
import { CostCalculatorMobile, FooterMobile, GooglePlayButton, HeaderB2BMobile, HeaderMobile } from 'components'
import Marquee from 'react-fast-marquee'

const defaults = require('languages/id.json')

const asset2Placeholder = 'https://i.postimg.cc/5t8mmyML/Frame-710.png'

const HomeNewProduct = () => {
  const { locale } = useContext(Context)
  const defaults = require('languages/id.json')

  const navigate = useNavigate()

  const btnStyleBlue = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#0B4571',
    border: 'none',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
  }
  const btnStyleWhite = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    border: 'none',
    color: '#0B4571',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
    width: 'fit-content',
  }

  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const isMobile = width <= 768

  // TOP HEADER BAR
  const [showDropdown, setShowDropdown] = useState(false)
  const [animating, setAnimating] = useState(false)
  const handleClick = () => {
    setAnimating(true)
    setShowDropdown(!showDropdown)
    console.log(animating)
  }
  const handleAnimationEnd = () => {
    setAnimating(false)
  }

  // KV CAROUSEL
  const [currentIndex, setCurrentIndex] = useState(0)
  const KVList = [
    { img: HomeKVMobile },
    { img: HomeKVMobile },
    { img: HomeKVMobile },
  ]
  const renderKVCarousel = () => {
    return KVList.map((item, index) => (
      <div
        key={index}
        style={{
          scrollSnapAlign: 'start',
          backgroundSize: 'cover',
          height: '100%',
          maxWidth: '100%', 
          flexShrink: 0,
          position: 'relative',
        }}
      >
        <img
          effect='blur'
          alt='top-banner'
          src={item.img}
          loading='lazy'
          style={{
            height: '100%',
            width: '100%', 
            objectFit: 'cover',
          }}
        />
        <div style={{
          width: '100%',
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, -10%)',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2rem', color: '#103856', marginBottom: '0.4rem' }}>
            Electrum H3i
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#103856', marginBottom: '1rem' }}>
            Bikin Perjalananmu Lebih Keren
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              See More <ArrowRight fill="#fff" />
            </button>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'transparent',
              border: 'none',
              color: '#0B4571',
              border: '3px solid #0B4571',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              Get Yours <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }
  const handleIndicatorClick = (index) => {
    setCurrentIndex(index)
    const carousel = document.getElementById('proklamasi-kv-carousel')
    carousel.scrollTo({ left: index * carousel.clientWidth, behavior: 'smooth' })
  }
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const nextIndex = (currentIndex + 1) % KVList.length
  //     setCurrentIndex(nextIndex)
  //     const carousel = document.getElementById('proklamasi-kv-carousel')
  //     carousel.scrollTo({ left: nextIndex * carousel.clientWidth, behavior: 'smooth' })
  //   }, 3000)
  //   return () => clearInterval(interval)
  // }, [currentIndex, KVList.length])
  const handleScrollCarousel = (direction) => {
    const container = document.getElementById('proklamasi-kv-carousel')
    const containerWidth = container.clientWidth
    container.scrollTo({
      left: container.scrollLeft + direction * containerWidth,
      behavior: 'smooth',
    })
  }

  // TESTIMONY
  const testimoniesList = [
    {
      rate: 5,
      name: 'Rian Ernest',
      occupation: 'Politisi & Pengacara',
      picture: Reviewer1,
      reviewBold: '“Enak banget manuvernya,',
      review: 'terutama pas macet. Torsinya terjaga dan bentuknya compact jadi enak banget.”',
    },
    {
      rate: 5,
      name: 'Putra Pratama Purwanto',
      occupation: 'Eksekutif Muda',
      picture: Reviewer2,
      reviewBold: '"Praktis banget buat swap & go.',
      review: 'Tukar baterainya nggak lama. Terus, buat bayar energi juga simpel, bisa langsung transaksi di aplikasi.”',
    },
    {
      rate: 5,
      name: 'Alif Fajar',
      occupation: 'Pemerhati Otomotif 10+ tahun',
      picture: Reviewer3,
      reviewBold: '"Murah banget',
      review: 'ketimbang bensin! Beli 5 Electrum Pulsa (EP) seharga 15rb aja bisa buat jarak hingga 120 km."',
    },
    {
      rate: 5,
      name: 'Pak Madya',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684576/pak-madya_hhqdwl.png',
      review: '“Cocok banget, istri saya juga nyaman dibonceng. Makin manteb mau ambil H3i”',
    },
    {
      rate: 5,
      name: 'Vandy Kusumawardhana',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684576/vandy-kusumawardhana_lnetbx.png',
      review: '“Secara keseluruhan motornya enak, bagasi luas dan ternyata tukar baterai di BSS mudah dan cepat”',
    },
    {
      rate: 5,
      name: 'Rizky Anugrah Rafi',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684578/rizky-anugrah-rafi_x4zoal.png',
      review: '“Motornya enak untuk dalem kota. Buat macet-macet enak karena dimensinya cukup dan ringan. Jok enak buat berdua. Riding position enak”',
    },
    {
      rate: 5,
      name: 'Eky Nur Romadhon',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684569/eky-nur-romadhon_xr4pjf.png',
      review: '“Motornya nyaman dan irit sekali, 15ribu bisa buat 4x ganti batre. Bagasi dan deck luas, enak bisa buat bawa barang. Sendiri atau berdua nyaman”',
    },
    {
      rate: 5,
      name: 'Muhammad Iqbal Tawakal',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684574/muhammad-iqbal-tawakal_qmnwsg.png',
      review: '“Program sepekan sangat membantu bagi yang ingin merasakan experience  menggunakan motor listrik electrum dalam pengaplikasian sehari-hari apakah cocok untuk kebutuhan kita atau tidak.”',
    },
    {
      rate: 5,
      name: 'Dede Kurniawan',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1732085032/dede-kurniawan_splfjx.png',
      review: '"Enak dibawa dan enteng, untuk selap-selip enak apalagi di mode sport dan tidak mengecewakan saat dibawa"',
    },
    {
      rate: 5,
      name: 'Farouk Anoz',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1732085032/farouk-anoz_vdxgbm.png',
      review: '"Awalnya skeptis, tapi pas coba ternyata asyik juga. Dibawanya enak untuk harian dan pastinya berasa bgt hematnya dibanding BBM"',
    },
  ]
  const renderTestimonies = () => {
    return testimoniesList.map((item, index) => (
      <div
        key={index}
        style={{
          minWidth: '17.5rem',
          padding: '1.75rem',
          borderRadius: '0.5rem',
          backgroundColor: 'white',
          boxShadow: '0px 4px 20px 0px rgba(0,0,0,0.1)',
        }}
      >
        {/* TOP */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <img loading='lazy' effect='blur' src={item.picture} alt='user' width={40} style={{ borderRadius: '100%' }} />
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.2rem', marginLeft: '0.85rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', color: '#103856', fontSize: '1rem' }}>
                {item.name}
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#103856', fontSize: '0.85rem' }}>
                {item.occupation}
              </div>
              <StarRating rate={5} />
            </div>
          </div>
        </div>
        {/* SEPARATOR */}
        <div style={{ borderBottom: '0.15rem #ECECEC solid', margin: '1rem 0' }} />
        {/* REVIEW */}
        <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#103856', fontSize: '1rem' }}>
          <b>{item.reviewBold}</b>{' '}{item.review}
        </div>
      </div>
    ))
  }

  // SMART TECH
  const smartTechFeatsList = [
    {
      title: 'Sekali Tap Untuk Top Up',
      description: 'Lorem ipsum sit dolor amet',
    },
    {
      title: 'Mudahnya Cari BSS',
      description: 'Lorem ipsum sit dolor amet',
    },
    {
      title: 'Pantau Kondisi Motor',
      description: 'Lorem ipsum sit dolor amet',
    },
    {
      title: 'Selalu Terkoneksi',
      description: 'Lorem ipsum sit dolor amet',
    },
  ]
  const renderSmartTechFeats = () => {
    return smartTechFeatsList.map((item, index) => (
      <div style={{ textAlign: 'center' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '0.85rem', color: '#1ECECA' }}>
          {item.title}
        </div>
        {/* <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#103856' }}>
          {item.description}
        </div> */}
      </div>
    ))
  }

  // LATEST NEWS
  const renderLatestNews = () => {
    const indexes = [1, 0, 4]
    const newsDataArr = indexes.map(idx => newsData[idx])
    return newsDataArr.map((item, idx) => (
      <div key={idx}>
        <img loading='lazy'  src={item.image} alt='placeholder' style={{ width: '100%', borderRadius: '0.625rem 0.625rem 0 0' }} />
        <div style={{
          backgroundColor: 'white',
          padding: '1.5rem',
          borderRadius: '0 0 0.625rem 0.625rem',
        }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.125rem', color: '#103856' }}>
            {item.title}
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', color: '#71869C', marginTop: '0.75rem' }}>
            {item.description.substring(0, 100)}...
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            <button
              onClick={() => navigate(`/news/detail/${item.slug}`)}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
            >
              See News <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }

  const ColoredText = styled.span`
    background: linear-gradient(to right, #ADEEEC, #1CA6A3);
    color: transparent;
    background-clip: text;
  `

  return (
    <div style={{
      padding: isMobile ? 'initial' : '0 33.5%',
      backgroundColor: '#103856',
    }}>
      <div
        style={{
          height: '100vh',
          overflowY: 'scroll',
          overflowX: 'hidden',
          backgroundColor: '#F0F8FE',
        }}
      >
        <HeaderB2BMobile />

        {/* TOP BANNER */}
        <div style={{
          padding: '3rem 2rem',
          backgroundImage: `url(${B2BBackground1Mobile})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minHeight: '70vh',
          height: '70%',
          position: 'relative',
          zIndex: 1,
        }}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '1.25rem',
            backgroundColor: 'rgba(53, 150, 204, 0.2)',
            backdropFilter: 'blur(0.5rem)',
            padding: '2rem 1.5rem 8rem 1.5rem',
            margin: '0 1rem',
          }}>
            <div style={{ zIndex: 2 }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.65rem', color: 'white', textAlign: 'center', marginBottom: '1rem' }}>
                Electrum For Business
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', color: 'white', textAlign: 'center' }}>
                Empowering your fleet with <br /> reliable performance, efficiency, <br /> and sustainable growth.
              </div>
            </div>
          </div>
          <img src={B2BTopThumbnail} alt="b2b-thumbnail"
            style={{
              width: '100%',
              marginTop: '-6.5rem',
              position: 'relative',
            }}
          />
          <img src={ArrowRightGradient} alt='arrow-gradient'
            style={{
              height: '2rem',
              marginTop: '1.25rem',
            }}
          />
        </div>

        {/* FOR BUSINESS */}
        <div style={{
          backgroundColor: '#0F212D',
          padding: '3rem 0rem 10rem 0rem',
          borderRadius: '1.25rem',
          position: 'relative',
          marginTop: '-5rem',
          zIndex: 2,
        }}>
          <div>
            <div style={{ textAlign: 'center' }}>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.75rem', color: 'white', marginBottom: '1rem' }}>
                Electrum for Business
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', color: '#AEC7E2', margin: '0.5rem 0rem 2rem 0rem' }}>
                <b>Electrum for Business</b> menawarkan opsi sewa <br/> atau beli kendaraan listrik roda dua yang fleksibel <br/> untuk operasional bisnis Anda sekaligus mendukung keberlanjutan lingkungan.
              </div>
            </div>
            <img alt='ev-business' src={EVBusinessDark} style={{ width: '100%' }} />
          </div>
        </div>

        {/* ELEVATE */}
        <div style={{
          margin: '-9rem 2rem 0rem 2rem',
          borderRadius: '1.25rem',
          position: 'relative',
          zIndex: 3,
        }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '2.5rem' }}>
            <img src={ArrowLeftGradient} alt='arrow-gradient' style={{ height: '2rem' }} />
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <div style={{ padding: '1.5rem 1.5rem 2rem 1.5rem', backgroundColor: '#103856', borderRadius: '1.25rem 0 0 1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white', marginBottom: '0.85rem' }}>
                Elevate Your Business with Electrum
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.875rem', color: '#AEC7E2' }}>
                Built for durability and dependable performance, even in demanding operations.
              </div>
            </div>
            <div style={{ backgroundColor: '#103856', borderRadius: '0 1.25rem 1.25rem 0' }}>
              <img loading='lazy' alt='smart-tech' src={ElevateMobile} style={{ width: '100%', height: '100%', borderRadius: '1.25rem' }} />
            </div>
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1.125rem',
            marginTop: '-1rem',
          }}>
            <div style={{
              padding: '2rem',
              borderRadius: '1rem',
              backgroundColor: 'rgba(18, 91, 146, 0.5)',
              backdropFilter: 'blur(1rem)',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.8rem',
            }}>
              <HandKey />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white' }}>
                Flexible Ownership Options
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#D9E7F5', marginTop: '-0.5rem' }}>
                Buy or lease Electrum Bikes to suit your business needs.
              </div>
            </div>
            <div style={{
              padding: '2rem',
              borderRadius: '1rem',
              backgroundColor: 'rgba(18, 91, 146, 0.5)',
              backdropFilter: 'blur(1rem)',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.8rem',
            }}>
              <ThreeCircles />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white' }}>
                Seamless Fleet Conversion
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#D9E7F5', marginTop: '-0.5rem' }}>
                Transition to EV solutions with expert guidance and ease.
              </div>
            </div>
            <div style={{
              padding: '2rem',
              borderRadius: '1rem',
              backgroundColor: 'rgba(18, 91, 146, 0.5)',
              backdropFilter: 'blur(1rem)',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '0.8rem',
            }}>
              <NetConnection />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white' }}>
                Comprehensive Support
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#D9E7F5', marginTop: '-0.5rem' }}>
                Gain access to Electrum's advanced Digital and Energy Infrastructure.
              </div>
            </div>
          </div>
        </div>

        {/* REST OF THE COMPONENT */}
        <div style={{
          padding: '55rem 2rem 4rem 2rem',
          backgroundImage: `url(${B2BWallpaperBG})`,
          backgroundSize: 'cover',
          marginTop: '-50rem',
        }}>
          {/* WHY CHOOSE ELECTRUM */}
          <div style={{ marginBottom: '1.5rem' }}>
            <img src={ArrowRightGradient} alt='arrow-gradient' style={{ height: '2rem' }} />
          </div>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: 'white', textAlign: 'center', marginBottom: '1rem' }}>
            Why Choose Electrum
          </div>
          <img src={EVWhyChoose} alt='why-choose' style={{ width: '100%', borderRadius: '0.55rem' }} />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '1.125rem',
            marginTop: '-2.5rem',
            padding: '3.75rem 1.5rem 1.5rem 1.5rem',
            backgroundColor: 'rgba(18, 91, 146, 0.4)',
            borderRadius: '1.25rem',
          }}>
            <div style={{
              padding: '2rem',
              borderRadius: '1rem',
              backgroundColor: 'rgba(18, 91, 146, 0.3)',
              backdropFilter: 'blur(1rem)',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
            }}>
              <PiggyBank size='50' />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white' }}>
                Save up to 40%
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#D9E7F5' }}>
                Built for durability and dependable performance, even in demanding operations.
              </div>
            </div>
            <div style={{
              padding: '2rem',
              borderRadius: '1rem',
              backgroundColor: 'rgba(18, 91, 146, 0.3)',
              backdropFilter: 'blur(1rem)',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
            }}>
              <MoneyBag size='50' />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white' }}>
                Boost Revenue
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#D9E7F5' }}>
                Improve profitability by maximizing fleet efficiency and minimizing downtime.
              </div>
            </div>
            <div style={{
              padding: '2rem',
              borderRadius: '1rem',
              backgroundColor: 'rgba(18, 91, 146, 0.3)',
              backdropFilter: 'blur(1rem)',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '1rem',
            }}>
              <CloudCog size='50' />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: 'white' }}>
                Real-Time Control
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#D9E7F5' }}>
                Utilize IoT technology for full visibility, proactive monitoring, and asset management.
              </div>
            </div>
          </div>
          {/* DASHBOARD EXP */}
          <div style={{ marginTop: '5.5rem' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1.5rem' }}>
              <img src={ArrowLeftGradient} alt='arrow-gradient' style={{ height: '2rem' }} />
            </div>
            <div style={{
              backgroundImage: `url(${DashboardExpMobile})`,
              backgroundSize: 'cover',
              paddingTop: '1.5rem',
              maxWidth: '100%',
              minHeight: '26.75rem',
            }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: 'white', textAlign: 'center', marginBottom: '1rem' }}>
                  Excellent Dashboard <br/> Experience with Electrum
                </div>
                <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#AEC7E2', textAlign: 'center' }}>
                  <b>Electrum for Business</b> menawarkan opsi sewa <br/> atau beli kendaraan listrik roda dua yang <br/> fleksibel untuk operasional bisnis Anda <br/> sekaligus mendukung keberlanjutan lingkungan.
                </div>
                <div style={{ display: 'flex', columnGap: '1rem', marginTop: '1.5rem' }}>
                  <button
                    style={btnStyleBlue}
                    onClick={() => navigate('/get-yours')}
                  >
                    CTA 1 <ArrowRight fill="#fff" />
                  </button>
                  <button
                    style={btnStyleWhite}
                    onClick={() => navigate('/products/h3i')}
                  >
                    CTA 2 <ArrowRight fill="#0B4571" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterMobile />
      </div>
    </div>
  )
}

export default HomeNewProduct
