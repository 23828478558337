import { Input } from 'antd'
import { ArrowRight } from 'assets/icons'
import { AboutTD1, AboutTD2, AboutTD3, AboutTD4, BSSSwap, BatteryCharge, BatterySwap, ColorAmber, ColorBeige, ColorDerawanBlue, ColorGrey, ColorIceNavy, ColorIvoryWhite, ColorMidnightBromo, ColorRedCherry, ColorViridianGreen, DarkGradientMobile, Feature1, Feature2, Feature3, GetNowPlaceholderMobile, IntroProduct, IntroProductMobile, MickeyPortrait, MickeySquare, MobileKV, PBB, PSB, ProductKVH5IntroMobile, ProductKVH5Mobile, ProductKVMobile, TechDesign1, TechDesign2, TechDesign3, Understand1, Understand2, Understand3, Understand4, Understand5, Understand6 } from 'assets/images'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const bannerPlaceholder = 'https://i.postimg.cc/fRNsGnYs/Frame-811270.png'

const { TextArea } = Input

const H3 = () => {
  const navigate = useNavigate()

  // COLOR CAROUSEL
  const [currentColorIndex, setColorCurrentIndex] = useState(0)
  const ColorList = [
    // { img: ColorIvoryWhite, color: 'Ivory White', palette: '#e5e5e5' },
    // { img: ColorRedCherry, color: 'Red Cherry', palette: '#f04c41' },
    // { img: ColorAmber, color: 'Amber', palette: '#f49f25' },
    // { img: ColorIceNavy, color: 'Ice Navy', palette: '#a7d3dd' },
    // { img: ColorViridianGreen, color: 'Viridian Green', palette: '#3f7772' },
    // { img: ColorBeige, color: 'Beige', palette: '#d5cdc2' },
    // { img: ColorGrey, color: 'Grey', palette: '#808388' },
    { img: ColorDerawanBlue, color: 'Derawan Blue', palette: '#40C0C0' },
    { img: ColorMidnightBromo, color: 'Midnight Bromo', palette: '#0B4571' },
  ]
  const renderColorCarousel = () => {
    return ColorList.map((item, index) => (
      <div key={index}>
        <img loading='lazy'  alt={item.color} src={item.img} style={{ width: '100vw', marginBottom: '3rem', scrollSnapAlign: 'start' }} />
      </div>
    ))
  }
  const renderColorBar = () => {
    return ColorList.map((item, index) => (
      <div style={currentColorIndex === index ? {
        display: 'inline-block',
        padding: '0.2rem',
        boxShadow: `0px 0px 10px 0px ${item.palette}`,
        borderRadius: '20rem',
      } : {
        padding: '0rem',
        boxShadow: 'none',
      }}>
        <div
          style={{ cursor: 'pointer', width: '2.25rem', height: '0.5rem', borderRadius: '20rem', backgroundColor: item.palette }}
          onClick={() => handleColorCarouselClick(index)}
        />
      </div>
    ))
  }
  const handleScrollColorCarousel = (direction) => {
    const container = document.getElementById('color-carousel')
    const containerWidth = container.clientWidth
    container.scrollTo({
      left: container.scrollLeft + direction * containerWidth,
      behavior: 'instant',
    })
  }
  const handleColorCarouselClick = (index) => {
    setColorCurrentIndex(index)
    const carousel = document.getElementById('color-carousel')
    carousel.scrollTo({ left: index * window.innerWidth, behavior: 'instant' })
  }

  // COLORED TEXT
  const ColoredText = styled.span`
    background: linear-gradient(0deg, #0B4571, #1ECECA);
    color: transparent;
    background-clip: text;
    font-family: Gilroy-Bold;
    font-size: 1rem;
    text-align: center;
  `

  // SPECIFICATIONS
  const specsDetail = [
    { label: 'Dimensi', value: '1.860 x 720 x 1.120 mm' },
    { label: 'Kecepatan Maks.', value: '90 Km/h' },
    { label: 'Berat', value: '110 kg' },
    { label: 'Output Tenaga Maks.', value: '5.2 KW' },
    { label: 'Kapasitas Baterai', value: '2 x 72v25Ah \n 1.8 KwH' },
    { label: 'Torsi Maks.', value: '160 Nm' },
    { label: 'Jarak Tempuh Maks.', value: '120 Km' },
    { label: 'Sistem Pengereman', value: 'Cakram Ganda' },
  ]

  return (
    <div style={{ backgroundColor: '#0C1D28' }}>

      {/* KV CAROUSEL */}
      {/* <div style={{ marginBottom: '4rem' }}>
        <div style={{ position: 'relative', width: '100%' }}>
          <div
            id="kv-carousel"
            style={{
              margin: '0 auto',
              display: 'flex',
              overflowX: 'scroll',
              scrollSnapType: 'x mandatory',
              scrollSnapStop: 'always',
            }}
            onScroll={(e) => {
              const container = e.target
              const scrollPosition = container.scrollLeft
              const containerWidth = container.clientWidth
              const newIndex = Math.round(scrollPosition / containerWidth)
              setCurrentIndex(newIndex)
            }}
          >
            {renderKVCarousel()}
            <button
              onClick={() => handleScrollCarousel(-1)}
              style={{
                position: 'absolute',
                top: '50%',
                left: '15px',
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                color: 'black',
                border: 'none',
                borderRadius: '50%',
                padding: '1px 11.5px 2.5px 11.5px',
                cursor: 'pointer',
                fontSize: '1.5rem',
                display: currentIndex === 0 ? 'none' : 'block',
              }}
            >
              &#8249;
            </button>
            <button
              onClick={() => handleScrollCarousel(1)}
              style={{
                position: 'absolute',
                top: '50%',
                right: '15px',
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                color: 'black',
                border: 'none',
                borderRadius: '50%',
                padding: '1px 11.5px 2.5px 11.5px',
                cursor: 'pointer',
                fontSize: '1.5rem',
                display: currentIndex === KVList.length-1 ? 'none' : 'block',
              }}
            >
              &#8250;
            </button>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
            position: 'relative',
          }}
        >
          {KVList.map((item, index) => (
            <div
              key={index}
              onClick={() => handleIndicatorClick(index)}
              style={{
                width: '30px',
                height: '6.5px',
                borderRadius: '50rem',
                backgroundColor: currentIndex === index ? '#1ECECA' : '#4F939D',
                margin: '0 5px',
                cursor: 'pointer',
              }}
            ></div>
          ))}
        </div>
      </div> */}

      {/* SINGLE BANNER */}
      <div
        style={{
          scrollSnapAlign: 'start',
          backgroundSize: 'cover',
          height: '100%',
          maxWidth: '100%', 
          flexShrink: 0,
          position: 'relative',
          marginBottom: '4rem',
        }}
      >
        <img
          effect='blur'
          alt='top-banner'
          src={ProductKVH5Mobile}
          loading='lazy'
          style={{
            height: '100%',
            width: '100%', 
            objectFit: 'cover',
          }}
        />
        <div style={{
          width: '100%',
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, -10%)',
          color: 'white',
          padding: '10px 0px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2rem', color: 'white', marginBottom: '0.4rem' }}>
            Electrum H5
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: 'white', marginBottom: '1rem' }}>
            Penuh Tenaga <br/> Penuh Kontrol
          </div>
          {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', rowGap: '0.65rem' }}>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
              onClick={() => navigate('/get-yours')}
            >
              Dapatkan Sekarang <ArrowRight fill="#fff" />
            </button>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                border: '3px solid white',
                color: '#0B4571',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.55rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
              onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}
            >
              Daftar Test-ride <ArrowRight fill="#0B4571" />
            </button>
          </div> */}
        </div>
      </div>

      {/* INTRO */}
      <div style={{
        backgroundColor: '#0A2437',
        borderRadius: '1.25rem',
        paddingTop: '0.25rem',
        marginBottom: '4rem',
      }}>
        <div style={{
          backgroundColor: '#103856',
          borderRadius: '1rem',
          margin: '1.75rem',
          padding: '1.75rem 2.25rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1.25rem',
        }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: 'white', textAlign: 'center' }}>
            Solusi Andal untuk <br/> Bisnis Maksimal
          </div>
          <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.75rem', color: '#AEC7E2', textAlign: 'center' }}>
            <b>Electrum H5</b> dirancang untuk Anda yang membutuhkan kendaraan tangguh dengan kapasitas besar dan daya tempuh lebih jauh.
          </div>
        </div>
        <img alt='kv-h5-mobile' src={ProductKVH5IntroMobile} style={{ width: '100%', marginTop: '1.75rem' }} />
      </div>

      {/* FEATURES */}
      <div style={{
        backgroundImage: `url("${DarkGradientMobile}")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        padding: '1.75rem',
      }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: 'white', textAlign: 'center', margin: '1.5rem 0' }}>
          Solusi Andal untuk <br/> Bisnis Maksimal
        </div>
        <div style={{
          backgroundColor: 'rgba(53, 150, 204, 0.2)',
          backdropFilter: 'blur(10px)',
          borderRadius: '0.75rem',
          padding: '2rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '2rem',
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem' }}>
            <img loading='lazy' alt='feature1' src={Feature1} style={{ width: '100%', borderRadius: '1.25rem 0' }} />
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', color: 'white' }}>
              Tenaga yang Tahan Lama
            </div>
            <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.75rem', color: '#D9E7F5', marginTop: '-0.75rem' }}>
              Ditenagai 2 baterai, membuat operasional lebih lancar tanpa sering isi ulang.
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem' }}>
            <img loading='lazy' alt='feature1' src={Feature2} style={{ width: '100%', borderRadius: '1.25rem 0' }} />
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', color: 'white' }}>
              Operasional Jadi Optimal
            </div>
            <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.75rem', color: '#D9E7F5', marginTop: '-0.75rem' }}>
              Performa tangguh dan tenaga besar untuk kebutuhan operasional yang berat dengan mudah.
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem' }}>
            <img loading='lazy' alt='feature1' src={Feature3} style={{ width: '100%', borderRadius: '1.25rem 0' }} />
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', color: 'white' }}>
              Bagasi Luas
            </div>
            <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.75rem', color: '#D9E7F5', marginTop: '-0.75rem' }}>
              Ukuran lebih besar, ideal untuk mengangkut lebih banyak barang dalam satu perjalanan.
            </div>
          </div>
        </div>
      </div>

      {/* BSS LOCATION */}
      <div style={{
        padding: '4rem 2rem 2rem 2rem',
      }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: 'white', textAlign: 'center', marginBottom: '1rem' }}>
          Lokasi Baterai yang tersebar <br />
          di Jadetabek
        </div>
        <img loading='lazy' alt='battery-location' src={BSSSwap} style={{ width: '100%' }} />
      </div>

      {/* CHOOSE COLOR */}
      <div style={{ padding: '4rem 0rem 2.5rem 0rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: 'white', textAlign: 'center', marginBottom: '1rem' }}>
          Koleksi Warna <span style={{ color: '#1ECECA' }}>Electrum H5</span>
        </div>
        {/* IMG PREVIEW */}
        <div style={{ position: 'relative', width: '100%' }}>
          <div
            id="color-carousel"
            style={{
              margin: '0 auto',
              display: 'flex',
              overflowX: 'scroll',
              scrollSnapType: 'x mandatory',
              scrollSnapStop: 'always',
              overflowX: 'hidden',
            }}
            onScroll={(e) => {
              const container = e.target
              const scrollPosition = container.scrollLeft
              const containerWidth = container.clientWidth
              const newIndex = Math.round(scrollPosition / containerWidth)
              setColorCurrentIndex(newIndex)
            }}
          >
            {renderColorCarousel()}
            {/* PREV BUTTON */}
            <button
              onClick={() => handleScrollColorCarousel(-1)}
              style={{
                position: 'absolute',
                top: '35%',
                left: '15px',
                transform: 'translateY(-35%)',
                backgroundColor: 'white',
                color: '#1ECECA',
                border: 'none',
                borderRadius: '50%',
                padding: '1px 11.5px 2.5px 11.5px',
                cursor: 'pointer',
                fontSize: '1.5rem',
                display: currentColorIndex === 0 ? 'none' : 'block',
              }}
            >
              &#8249;
            </button>
            {/* NEXT BUTTON */}
            <button
              onClick={() => handleScrollColorCarousel(1)}
              style={{
                position: 'absolute',
                top: '35%',
                right: '15px',
                transform: 'translateY(-35%)',
                backgroundColor: 'white',
                color: '#1ECECA',
                border: 'none',
                borderRadius: '50%',
                padding: '1px 11.5px 2.5px 11.5px',
                cursor: 'pointer',
                fontSize: '1.5rem',
                display: currentColorIndex === ColorList.length-1 ? 'none' : 'block',
              }}
            >
              &#8250;
            </button>
          </div>
        </div>
        {/* COLOR SELECTOR */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '2.25rem' }}>
          <div style={{
            padding: '0.8rem 1rem',
            display: 'flex',
            alignItems: 'center',
            columnGap: '0.75rem',
            boxShadow: '0px 4px 20px 0px rgba(0,0,0,0.1)',
            width: 'fit-content',
            borderRadius: '0.625rem',
            marginBottom: '1.25rem',
            backgroundColor: 'white',
          }}>
            {renderColorBar()}
          </div>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', color: 'white' }}>
            {ColorList[currentColorIndex].color}
          </div>
        </div>
        {/* SPECIFICATION */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{
            backgroundColor: '#103856',
            padding: '1.25rem',
            borderRadius: '1.25rem',
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: '1rem',
            rowGap: '1rem',
            justifyContent: 'center',
            margin: '0rem 2rem',
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                90 km/jam
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: 'white', textAlign: 'center' }}>
                Kecepatan Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                3.6 KwH
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: 'white', textAlign: 'center' }}>
                Kapasitas Baterai
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                120 km
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: 'white', textAlign: 'center' }}>
                Jarak Tempuh Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                160 Nm
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: 'white', textAlign: 'center' }}>
                Torsi Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                5.2 kW
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: 'white', textAlign: 'center' }}>
                Output Tenaga Maks.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SPECS DETAIL */}
      <div style={{ paddingBottom: '4rem' }}>
        <div style={{
          backgroundColor: '#103856',
          padding: '2.25rem',
          borderRadius: '1.25rem',
        }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', color: 'white' }}>
            Electrum H5
          </div>
          <div style={{ height: '0.5rem', width: '4.5rem', backgroundColor: '#F04C3E', margin: '1.25rem 0rem 1.65rem 0rem' }} />
          {/* 1ST SECT */}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.45rem' }}>
            {specsDetail.map((spec, index) => (
              <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', color: 'white' }}>
                  {spec.label}
                </div>
                <div
                  style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: 'white', textAlign: 'right', lineHeight: '2rem' }}
                  dangerouslySetInnerHTML={{ __html: spec.value.replace(/\n/g, "<br>") }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* H5 FORM */}
      <div style={{ paddingBottom: '4rem' }}>
        <div style={{
          backgroundColor: '#103856',
          padding: '2rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '2rem',
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.8rem' }}>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: 'white' }}>
                Solusi Kendaraan Listrik untuk Kemajuan Bisnis Anda
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C' }}>
                Ingin operasional bisnis lebih efisien dengan kendaraan listrik yang andal dan hemat energi?
                <br /><br />
                Hubungi kami sekarang dan temukan solusi terbaik untuk kebutuhan bisnis Anda.
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: 'white', padding: '1.5rem', borderRadius: '0.85rem', height: 'fit-content' }}>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Nama Lengkap
              </div>
              <Input name='fullName' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Nomor Telepon
              </div>
              <Input name='phoneNumber' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Alamat Email
              </div>
              <Input name='email' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Pesan
              </div>
              <TextArea rows={3} name='message' />
            </div>
            <div>
              <button
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '0.875rem',
                  color: 'white',
                  backgroundColor: '#0B4571',
                  border: '2px solid #0B4571',
                  padding: '0.5rem 0.85rem',
                  borderRadius: '0.375rem',
                  marginBottom: 'initial',
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default H3
