import { Input } from 'antd'
import { ArrowRight, IonLeaf, Motorcycle, Verified } from 'assets/icons'
import { AboutTD1, AboutTD2, AboutTD3, AboutTD4, BSS, BatteryCharge, BatterySwap, ColorAmber, ColorBeige, ColorDerawanBlue, ColorGrey, ColorIceNavy, ColorIvoryWhite, ColorMidnightBromo, ColorRedCherry, ColorViridianGreen, GetNowPlaceholderMobile, IntroProduct, IntroProductMobile, MickeyPortrait, MickeySquare, MobileKV, PBB, PSB, ProductKVH3IntroMobile, ProductKVH3Mobile, ProductKVH3V2Mobile, ProductKVMobile, TechDesign1, TechDesign2, TechDesign3, Understand1, Understand2, Understand3, Understand4, Understand5, Understand6 } from 'assets/images'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const bannerPlaceholder = 'https://i.postimg.cc/fRNsGnYs/Frame-811270.png'

const { TextArea } = Input

const H3 = () => {
  const navigate = useNavigate()

  // KV CAROUSEL
  const [currentIndex, setCurrentIndex] = useState(0)
  const KVList = [
    { img: ProductKVMobile },
    { img: ProductKVMobile },
    { img: ProductKVMobile },
  ]
  const renderKVCarousel = () => {
    return KVList.map((item, index) => (
      <div
        key={index}
        style={{
          scrollSnapAlign: 'start',
          backgroundSize: 'cover',
          height: '100%',
          maxWidth: '100%', 
          flexShrink: 0,
          position: 'relative',
        }}
      >
        <img
          effect='blur'
          alt='top-banner'
          src={item.img}
          loading='lazy'
          style={{
            height: '100%',
            width: '100%', 
            objectFit: 'cover',
          }}
        />
        <div style={{
          width: '100%',
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, -10%)',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2rem', color: '#103856', marginBottom: '0.4rem' }}>
            Electrum H3
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#103856', marginBottom: '1rem' }}>
            Bikin Perjalananmu Lebih Keren
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              See More <ArrowRight fill="#fff" />
            </button>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'transparent',
              border: 'none',
              color: '#0B4571',
              border: '3px solid #0B4571',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              Get Yours <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }
  const handleIndicatorClick = (index) => {
    setCurrentIndex(index)
    const carousel = document.getElementById('kv-carousel')
    carousel.scrollTo({ left: index * carousel.clientWidth, behavior: 'smooth' })
  }
  const handleScrollCarousel = (direction) => {
    const container = document.getElementById('kv-carousel')
    const containerWidth = container.clientWidth
    container.scrollTo({
      left: container.scrollLeft + direction * containerWidth,
      behavior: 'smooth',
    })
  }

  // COLOR CAROUSEL
  const [currentColorIndex, setColorCurrentIndex] = useState(0)
  const ColorList = [
    // { img: ColorIvoryWhite, color: 'Ivory White', palette: '#e5e5e5' },
    // { img: ColorRedCherry, color: 'Red Cherry', palette: '#f04c41' },
    // { img: ColorAmber, color: 'Amber', palette: '#f49f25' },
    // { img: ColorIceNavy, color: 'Ice Navy', palette: '#a7d3dd' },
    // { img: ColorViridianGreen, color: 'Viridian Green', palette: '#3f7772' },
    // { img: ColorBeige, color: 'Beige', palette: '#d5cdc2' },
    // { img: ColorGrey, color: 'Grey', palette: '#808388' },
    { img: ColorDerawanBlue, color: 'Derawan Blue', palette: '#40C0C0' },
    { img: ColorMidnightBromo, color: 'Midnight Bromo', palette: '#0B4571' },
  ]
  const renderColorCarousel = () => {
    return ColorList.map((item, index) => (
      <div key={index}>
        <img loading='lazy'  alt={item.color} src={item.img} style={{ width: '100vw', marginBottom: '3rem', scrollSnapAlign: 'start' }} />
      </div>
    ))
  }
  const renderColorBar = () => {
    return ColorList.map((item, index) => (
      <div style={currentColorIndex === index ? {
        display: 'inline-block',
        padding: '0.2rem',
        boxShadow: `0px 0px 10px 0px ${item.palette}`,
        borderRadius: '20rem',
      } : {
        padding: '0rem',
        boxShadow: 'none',
      }}>
        <div
          style={{ cursor: 'pointer', width: '2.25rem', height: '0.5rem', borderRadius: '20rem', backgroundColor: item.palette }}
          onClick={() => handleColorCarouselClick(index)}
        />
      </div>
    ))
  }
  const handleScrollColorCarousel = (direction) => {
    const container = document.getElementById('color-carousel')
    const containerWidth = container.clientWidth
    container.scrollTo({
      left: container.scrollLeft + direction * containerWidth,
      behavior: 'instant',
    })
  }
  const handleColorCarouselClick = (index) => {
    setColorCurrentIndex(index)
    const carousel = document.getElementById('color-carousel')
    carousel.scrollTo({ left: index * window.innerWidth, behavior: 'instant' })
  }

  // TECH DESIGN 2
  const gridItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
  }
  const imageStyle = {
    width: '100%',
  }
  const headerStyle = {
    fontFamily: 'Gilroy-Bold',
    fontSize: '1rem',
    color: '#103856',
  }
  const subheaderStyle = {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '0.75rem',
    color: '#71869C',
    marginTop: '-0.25rem',
  }
  const tdList = [
    {
      img: AboutTD1,
      header: 'Bagasi Luas',
      subheader: 'Nggak ada yang bisa ngalahin luasnya bagasi motor listrik Electrum H3. Mulai dari tas, topi, sepatu, jas hujan, sampai helm full face, semua muat!',
    },
    {
      img: AboutTD2,
      header: 'Desain Modern',
      subheader: 'Dibawa ngantor, morning ride, atau hangout, Electrum H3 bakal jadi fashion statement favorit kamu.',
    },
    {
      img: AboutTD3,
      header: 'Ruang Lega',
      subheader: 'Area motornya yang lega, mulai dari ruang kaki sampai jok, bikin sensasi berkendara lebih nyaman.',
    },
    {
      img: AboutTD4,
      header: 'Lincah & Ringan',
      subheader: 'Bobotnya yang ringan bikin Electrum H3 jadi paling sat-set dan lincah di jalan. Jalanan berbelok-belok? Nggak masalah!',
    },
  ]
  const renderTD = () => {
    return tdList.map((item, index) => (
      <div ley={index} style={gridItemStyle}>
        <img loading='lazy'  alt={`td${index+1}`} src={item.img} style={imageStyle} />
        <div style={headerStyle}>{item.header}</div>
        <div style={subheaderStyle}>{item.subheader}</div>
      </div>
    ))
  }

  // COLORED TEXT
  const ColoredText = styled.span`
    background: linear-gradient(0deg, #0B4571, #1ECECA);
    color: transparent;
    background-clip: text;
    font-family: Gilroy-Bold;
    font-size: 1rem;
    text-align: center;
  `

  // UNDERSTAND
  const understandArr = [
    {
      img: Understand1,
      title: 'Baterai Tahan Lama',
      description: 'Berbekal 1 buah baterai NCM yang tahan jarak tempuh hingga 65 km, riding ke tempat hangout after-office lebih dari cukup.',
    },
    {
      img: Understand2,
      title: '2 Mode Unggulan',
      description: '<b>Mode Sport</b> siap menjawab kebutuhan akselerasi dan kecepatan, sedangkan <b>Mode Smart</b> bantu kamu hemat energi tanpa mengurangi performa.',
    },
    {
      img: Understand3,
      title: 'Cruise Control & Reverse Assist',
      description: 'Fitur <b>Cruise Control</b> memungkinkan kamu aman berkendara dengan kecepatan konstan. Butuh bantuan mundur? Serahkan pada fitur <b>Reverse Assist</b>.',
    },
    {
      img: Understand4,
      title: 'Smart Key Remote',
      description: 'Dengan <b>Smart Key Remote</b>, kamu bisa menyalakan/mematikan motor, tanda alarm, hingga membuka jok. Semua kebutuhan dalam satu genggaman.',
    },
    {
      img: Understand5,
      title: 'Adaptive Speedometer',
      description: 'Tidak hanya menampilkan informasi performa motor dan baterai, warna panel speedometer dapat berubah secara adaptif mengikuti terang-gelapnya kondisi sekeliling saat berkendara.',
    },
    {
      img: Understand6,
      title: 'Pengisi daya USB',
      description: 'Nggak ada lagi cerita handphone-mu lowbat. Charge aja langsung lewat USB port di motor listrik Electrum kamu. Anti lowbat-lowbat club!',
    },
  ]
  const renderUnderstand = () => {
    return understandArr.map((item, index) => (
      <div key={index} style={{ minWidth: '17.5rem' }}>
        <img loading='lazy'  alt='understand' src={item.img} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        <div style={{ backgroundColor: 'white', borderRadius: '0 0 0.625rem 0.625rem', padding: '2rem 1.5rem 1.5rem 1.5rem', minHeight: '10.5rem', marginTop: '-0.5rem' }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', color: '#103856', marginBottom: '0.65rem' }}>
            {item.title}
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C' }}>
            {item.description}
          </div>
        </div>
      </div>
    ))
  }

  // SPECIFICATIONS
  const specsDetail = [
    { label: 'Dimensi', value: '1.900 x 770 x 1.020 mm' },
    { label: 'Kecepatan Maks.', value: '70 Km/h' },
    { label: 'Berat', value: '87 kg' },
    { label: 'Output Tenaga Maks.', value: '2.7 KW' },
    { label: 'Kapasitas Baterai', value: '1 x 72v25Ah \n 1.8 KwH' },
    { label: 'Torsi Maks.', value: '150 Nm' },
    { label: 'Jarak Tempuh Maks.', value: '65 Km' },
    { label: 'Sistem Pengereman', value: 'Cakram Ganda' },
  ]

  return (
    <div>

      {/* SINGLE BANNER */}
      <div
        style={{
          scrollSnapAlign: 'start',
          backgroundSize: 'cover',
          height: '100%',
          maxWidth: '100%', 
          flexShrink: 0,
          position: 'relative',
          marginBottom: '3rem',
        }}
      >
        <img
          effect='blur'
          alt='top-banner'
          // src={ProductKVH3Mobile}
          src={ProductKVH3V2Mobile}
          loading='lazy'
          style={{
            height: '100%',
            width: '100%', 
            objectFit: 'cover',
          }}
        />
        <div style={{
          width: '100%',
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, -10%)',
          color: 'white',
          padding: '10px 0px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2rem', color: 'white', marginBottom: '0.4rem' }}>
            Electrum H3
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: 'white', marginBottom: '1rem' }}>
            Lincah di Jalan dengan Bobot Ringan
          </div>
          {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', rowGap: '0.65rem' }}>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
              onClick={() => navigate('/get-yours')}
            >
              Dapatkan Sekarang <ArrowRight fill="#fff" />
            </button>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                border: '3px solid white',
                color: '#0B4571',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.55rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
              onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}
            >
              Daftar Test-ride <ArrowRight fill="#fff" />
            </button>
          </div> */}
        </div>
      </div>

      {/* INTRO */}
      <div style={{
        background: 'linear-gradient(180deg, #82BFEC 0%, #1ECECA 100%)',
        borderRadius: '1.25rem',
        padding: '1.85rem 1.85rem 0 1.85rem',
      }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', marginBottom: '1rem' }}>
          Motor Listrik Jagoan <br/> Bisnis Harian
        </div>
        <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: '#103856', marginBottom: '1.75rem' }}>
          <b>Electrum H3</b> adalah pilihan tepat untuk Anda yang mencari motor listrik hemat energi, fleksibel, dan dapat diandalkan untuk operasional harian.
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: '1rem',
          marginBottom: '1.5rem',
        }}>
          <div style={{
            backgroundColor: 'rgba(173, 240, 255, 0.4)',
            backdropFilter: 'blur(0.5rem)',
            borderRadius: '1.25rem',
            padding: '0.5rem 2rem',
            minHeight: '5.5rem',
            display: 'flex',
          }}>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '2rem' }}>
              <div>
                <IonLeaf size='50' />
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.9rem', color: '#103856' }}>
                Konsumsi daya rendah, lebih ekonomis untuk bisnis Anda.
              </div>
            </div>
          </div>
          <div style={{
            backgroundColor: 'rgba(173, 240, 255, 0.4)',
            backdropFilter: 'blur(0.5rem)',
            borderRadius: '1.25rem',
            padding: '0.5rem 2rem',
            minHeight: '5.5rem',
            display: 'flex',
          }}>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '2rem' }}>
              <div>
                <Motorcycle size='50' />
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.9rem', color: '#103856' }}>
                Desain ramping memudahkan driver untuk bermanuver tanpa cepat lelah.
              </div>
            </div>
          </div>
          <div style={{
            backgroundColor: 'rgba(173, 240, 255, 0.4)',
            backdropFilter: 'blur(0.5rem)',
            borderRadius: '1.25rem',
            padding: '0.5rem 2rem',
            minHeight: '5.5rem',
            display: 'flex',
          }}>
            <div style={{ display: 'flex', alignItems: 'center', columnGap: '2rem' }}>
              <div>
                <Verified size='50' />
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.9rem', color: '#103856' }}>
                Dipercaya oleh lebih dari 3.000 pengendara, dengan total perjalanan lebih dari 20.000.000 kilometer.
              </div>
            </div>
          </div>
        </div>
        <img alt='h3-intro-mobile' src={ProductKVH3IntroMobile} style={{ width: '100%' }} />
      </div>

      {/* BATTERY LOCATION */}
      <div style={{ padding: '5rem 2rem 1rem 2rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', textAlign: 'center' }}>
          Lokasi Baterai yang Tersebar <br />
          di Jadetabek
        </div>
        <div style={{ margin: '1rem 0rem', display: 'flex', justifyContent: 'center' }}>
          <img loading='lazy'  alt='bss' src={BSS} style={{ width: '100%' }} />
        </div>
      </div>

      {/* CHOOSE COLOR */}
      <div style={{ padding: '4rem 0rem 2.5rem 0rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', textAlign: 'center', marginBottom: '1rem' }}>
          Koleksi Warna <span style={{ color: '#1ECECA' }}>Electrum H3</span>
        </div>
        {/* IMG PREVIEW */}
        <div style={{ position: 'relative', width: '100%' }}>
          <div
            id="color-carousel"
            style={{
              margin: '0 auto',
              display: 'flex',
              overflowX: 'scroll',
              scrollSnapType: 'x mandatory',
              scrollSnapStop: 'always',
              overflowX: 'hidden',
            }}
            onScroll={(e) => {
              const container = e.target
              const scrollPosition = container.scrollLeft
              const containerWidth = container.clientWidth
              const newIndex = Math.round(scrollPosition / containerWidth)
              setColorCurrentIndex(newIndex)
            }}
          >
            {renderColorCarousel()}
            {/* PREV BUTTON */}
            <button
              onClick={() => handleScrollColorCarousel(-1)}
              style={{
                position: 'absolute',
                top: '35%',
                left: '15px',
                transform: 'translateY(-35%)',
                backgroundColor: 'white',
                color: '#1ECECA',
                border: 'none',
                borderRadius: '50%',
                padding: '1px 11.5px 2.5px 11.5px',
                cursor: 'pointer',
                fontSize: '1.5rem',
                display: currentColorIndex === 0 ? 'none' : 'block',
              }}
            >
              &#8249;
            </button>
            {/* NEXT BUTTON */}
            <button
              onClick={() => handleScrollColorCarousel(1)}
              style={{
                position: 'absolute',
                top: '35%',
                right: '15px',
                transform: 'translateY(-35%)',
                backgroundColor: 'white',
                color: '#1ECECA',
                border: 'none',
                borderRadius: '50%',
                padding: '1px 11.5px 2.5px 11.5px',
                cursor: 'pointer',
                fontSize: '1.5rem',
                display: currentColorIndex === ColorList.length-1 ? 'none' : 'block',
              }}
            >
              &#8250;
            </button>
          </div>
        </div>
        {/* COLOR SELECTOR */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '2.25rem' }}>
          <div style={{
            padding: '0.8rem 1rem',
            display: 'flex',
            alignItems: 'center',
            columnGap: '0.75rem',
            boxShadow: '0px 4px 20px 0px rgba(0,0,0,0.1)',
            width: 'fit-content',
            borderRadius: '0.625rem',
            marginBottom: '1.25rem',
            backgroundColor: 'white',
          }}>
            {renderColorBar()}
          </div>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem' }}>
            {ColorList[currentColorIndex].color}
          </div>
        </div>
        {/* SPECIFICATION */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{
            backgroundColor: '#FFFFFF',
            padding: '1.25rem',
            borderRadius: '1.25rem',
            display: 'flex',
            flexWrap: 'wrap',
            columnGap: '1rem',
            rowGap: '1rem',
            justifyContent: 'center',
            margin: '0rem 2rem',
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                70 km/jam
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Kecepatan Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                1.8 KwH
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Kapasitas Baterai
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                65 km
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Jarak Tempuh Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                150 Nm
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Torsi Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                2.7 kW
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Output Tenaga Maks.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* SPECS DETAIL */}
      <div style={{ paddingBottom: '4rem' }}>
        <div style={{
          backgroundColor: 'white',
          padding: '2.25rem',
          borderRadius: '1.25rem',
        }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', color: 'black' }}>
            Electrum H5
          </div>
          <div style={{ height: '0.5rem', width: '4.5rem', backgroundColor: '#F04C3E', margin: '1.25rem 0rem 1.65rem 0rem' }} />
          {/* 1ST SECT */}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.45rem' }}>
            {specsDetail.map((spec, index) => (
              <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', color: '#103856' }}>
                  {spec.label}
                </div>
                <div
                  style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#103856', textAlign: 'right', lineHeight: '2rem' }}
                  dangerouslySetInnerHTML={{ __html: spec.value.replace(/\n/g, "<br>") }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* H5 FORM */}
      <div style={{ paddingBottom: '4rem' }}>
        <div style={{
          backgroundColor: 'white',
          padding: '2rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '2rem',
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.8rem' }}>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856' }}>
                Solusi Kendaraan Listrik untuk Kemajuan Bisnis Anda
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#103856' }}>
                Ingin operasional bisnis lebih efisien dengan kendaraan listrik yang andal dan hemat energi?
                <br /><br />
                Hubungi kami sekarang dan temukan solusi terbaik untuk kebutuhan bisnis Anda.
              </div>
            </div>
          </div>
          <div style={{ backgroundColor: '#103856', padding: '1.5rem', borderRadius: '0.85rem', height: 'fit-content' }}>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: 'white', marginBottom: '0.25rem' }}>
                Nama Lengkap
              </div>
              <Input name='fullName' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: 'white', marginBottom: '0.25rem' }}>
                Nomor Telepon
              </div>
              <Input name='phoneNumber' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: 'white', marginBottom: '0.25rem' }}>
                Alamat Email
              </div>
              <Input name='email' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: 'white', marginBottom: '0.25rem' }}>
                Pesan
              </div>
              <TextArea rows={3} name='message' />
            </div>
            <div>
              <button
                style={{
                  cursor: 'pointer',
                  fontFamily: 'Gilroy-Bold',
                  fontSize: '0.875rem',
                  color: '#0B4571',
                  backgroundColor: 'white',
                  border: '2px solid white',
                  padding: '0.5rem 0.85rem',
                  borderRadius: '0.375rem',
                  marginBottom: 'initial',
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default H3
