import { getBlogFromCMS } from 'apis/api'
import { useQuery } from 'react-query'

export const useGetBlogFromCMS = () => {
  return useQuery(
    ['cms-blog-list'],
    () => getBlogFromCMS(),
    { keepPreviousData: true },
  )
}
