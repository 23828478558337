import * as React from "react"

const HandKey = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="45"
    fill="none"
    viewBox="0 0 47 55"
  >
    <path
      fill="#fff"
      d="M27.912 3.047a11.356 11.356 0 0 0-10.79 14.86L8.956 26.07a1.13 1.13 0 0 0-.332.802v5.673A1.135 1.135 0 0 0 9.76 33.68h5.672a1.135 1.135 0 0 0 1.135-1.135v-2.269h2.269a1.134 1.134 0 0 0 1.135-1.134v-2.27h2.269a1.13 1.13 0 0 0 .802-.331l1.356-1.358a11.346 11.346 0 1 0 3.514-22.136m2.837 10.778a2.27 2.27 0 1 1 0-4.54 2.27 2.27 0 0 1 0 4.54"
    ></path>
    <path
      fill="#E6E7E8"
      d="M45.348 42.782a3.15 3.15 0 0 0 .67-4.404 3.15 3.15 0 0 0-4.405-.67l-9.43 6.95H22.68c-.693 0-1.26-.568-1.26-1.261 0-.694.567-1.26 1.26-1.26h6.304a2.52 2.52 0 0 0 2.52-2.522 2.52 2.52 0 0 0-2.52-2.521H16.51c-2.292 0-4.514.78-6.303 2.206l-3.537 2.836h-2.9a2.52 2.52 0 0 0-2.521 2.521V49.7a2.52 2.52 0 0 0 2.521 2.521h25.252c2.285 0 4.515-.733 6.358-2.088l9.975-7.35zm-28.845-.654a.04.04 0 0 1-.006.053.04.04 0 0 1-.053-.002.04.04 0 0 1-.011-.035.04.04 0 0 1-.005-.037.04.04 0 0 1 .011-.015.04.04 0 0 1 .017-.01.039.039 0 0 1 .046.026.04.04 0 0 1 .001.02"
    ></path>
    <rect
      width="5.105"
      height="15.316"
      x="0.109"
      y="39.352"
      fill="#fff"
      rx="2.553"
    ></rect>
  </svg>
)

export default HandKey
