import * as React from "react";

const Motorcycle = ({ size = '72' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 80 80"
  >
    <path
      fill="#103856"
      d="M58.768 17.781s-1.393.23-1.437.246l-1.97.422a.69.69 0 0 0-.386.843l1.064 3.43c-.178.02-.355.022-.453-.114a2.2 2.2 0 0 1-.181-.384l-.754-2.315s-2.672-.793-3.122-.793h-3.142a1.02 1.02 0 0 0 0 2.04h3.142q.106-.01.283.013c.157.503.739 2.401.731 2.787-.006.35-.538.713-.563 1.03-.021.278.545 1.193.545 1.193s3.449-1.887 4.615-2.493l-.002-.005c.702.197 1.538.34 1.538.34.42.134.846-.193.846-.65v-4.918c0-.416-.358-.735-.754-.672M3.859 47.36l-2.031 2.935c-.193.278.171.601.424.377l2.628-2.324a.72.72 0 0 0-.053-1.117q-.007-.004-.013-.01a.664.664 0 0 0-.955.14M6.27 43.954a1.577 1.577 0 0 0-1.605 2.32 1.58 1.58 0 0 0 1.623.795 1.577 1.577 0 0 0-.018-3.115M68.007 35.6c-.23-.089-1.597-.091-1.597-.091-.429-.025-.8.334-.826.8l-.02.346c-.027.465.3.864.729.889l2.904.167M11.618 31.792l29.24 8.025c.849.233 2.694-.746 2.721-1.626 0 0-.428-7.089-4.712-7.755-1.23-.192-5.302-.575-8.91-.288s-5.086-.899-6.74-1.374c-2.569-.738-10.665-.766-10.665-.766l-.025.035a1.36 1.36 0 0 0-1.268.777l-.51 1.078a1.36 1.36 0 0 0 .869 1.894M71.8 46.593a8.9 8.9 0 0 0-3.105-.554c-4.95 0-8.976 4.027-8.976 8.976q0 .549.064 1.08c.535 4.443 4.327 7.897 8.912 7.897 4.95 0 8.977-4.027 8.977-8.977 0-3.857-2.448-7.155-5.871-8.422m-3.105 13.048a4.63 4.63 0 0 1-4.626-4.626 4.63 4.63 0 0 1 4.626-4.626 4.63 4.63 0 0 1 4.626 4.626 4.63 4.63 0 0 1-4.626 4.626M21.658 53.314a8.9 8.9 0 0 0-1.656-3.708 8.97 8.97 0 0 0-9.542-3.245c-3.796 1.047-6.593 4.53-6.593 8.655 0 4.95 4.027 8.977 8.977 8.977 4.407 0 8.084-3.194 8.836-7.39a9 9 0 0 0-.022-3.289m-8.814 6.327a4.63 4.63 0 0 1-4.626-4.626 4.63 4.63 0 0 1 4.626-4.626 4.63 4.63 0 0 1 4.626 4.626 4.63 4.63 0 0 1-4.626 4.626"
    ></path>
    <path
      fill="#103856"
      d="M75.31 45.75c-.745-1.584-2.514-4.033-6.466-4.681-.246-.04-.767.122-.929.298l-3.2 3.489c-.317.345.198.947.644.76 3.8-1.59 7.384-.217 9.202.758.444.238.954-.187.749-.625"
    ></path>
    <path
      fill="url(#paint0_linear_7465_1285)"
      d="M58.68 56.341c-.174-.456-.167-.953-.16-1.431l.002-.28c0-1.176.212-2.323.63-3.413h-6.095a2.26 2.26 0 0 1-2.156 1.622h-8.51c-.681 0-1.317-.304-1.745-.833a2.24 2.24 0 0 1-.452-1.879l1.568-7.385a1.84 1.84 0 0 0-1.294-2.146l-28.315-8.04-.002-.005a39 39 0 0 1-2.41-.74c-.337-.12-.43-.448-.531-.925-.165-.78-.045-1.9.805-2.257.34-.143.133-.7-.24-.7-.192 0-.61.138-.845.368-.995.97-.673 3.253-.67 3.276.043.293.253 1.051.955 1.292.22.074.345.099.782.22l.26.072-.139.232q-.059.096-.094.175l-3.17 8.179a1.83 1.83 0 0 0 .348 2.035c.013.013.095.077.182.132.346.216 1.238.776 2.48 1.552a9.6 9.6 0 0 1 2.82-.423 9.62 9.62 0 0 1 7.834 4.063 9.6 9.6 0 0 1 1.649 4.083v.01l.022.216c.094.917.19 1.866 1.208 2.633 1.326 1 3.96 1.486 8.053 1.486h26.346l.205-.002c.298.004.543-.105.673-.292.148-.211.148-.52.006-.895"
    ></path>
    <path
      fill="url(#paint1_linear_7465_1285)"
      d="M60.097 37.263 56.195 37c-.36-.121-.186-1.081.092-1.046l3.886.36a.13.13 0 0 1 .12.142l-.054.687a.134.134 0 0 1-.142.12"
    ></path>
    <path
      fill="url(#paint2_linear_7465_1285)"
      d="M60.347 36.005a.394.394 0 0 1 .358.424l-.003.05 4.1.275a.256.256 0 0 0 .271-.272l-.01-.153v-.04a1.54 1.54 0 0 1 .462-1.005c.28-.27.641-.408 1.015-.387l.051.003a.256.256 0 0 0 .207-.425l-7.799-8.815a1.83 1.83 0 0 0-2.115-.463l-2.766 1.22a1.84 1.84 0 0 0-1.02 2.198l.008.021c.097.262 1.993 5.394 2.588 7.027.088-.005 4.653.342 4.653.342"
    ></path>
    <path
      fill="#103856"
      d="m68.502 38.349-2.159-.124c-.622-.036-1.092-.27-1.228-.838a.29.29 0 0 0-.261-.22l-4.19-.284-.025.32a.39.39 0 0 1-.392.363l-.03-.001-4.386-.295c-.048.41-.107.857-.124.94L53.21 50.453a.29.29 0 0 0 .284.348h5.65a.29.29 0 0 0 .248-.14c2.513-4.175 7.546-9.825 9.313-11.83a.29.29 0 0 0-.202-.482M12.475 39.036l.24-.667a.16.16 0 0 0-.016-.15l-.02-.025-.387-.456a.14.14 0 0 1 .082-.23l.219-.039.638-.114a.14.14 0 0 1 .142.05l.511.596a.14.14 0 0 1 .027.147c-.1.272-.197.545-.299.817-.148.398-.29.8-.435 1.199l-.11.306a.14.14 0 0 1-.147.094.14.14 0 0 1-.094-.051l-.133-.157-.421-.497-.01-.012a.14.14 0 0 1-.026-.142q.12-.337.239-.669M15.178 40.965l-.155.43a.1.1 0 0 1-.033.05.1.1 0 0 1-.056.026l-.486.087-.552.1a.11.11 0 0 1-.091-.022l-.018-.019q-.292-.346-.583-.694l-.008-.01a.105.105 0 0 1 .062-.17l.25-.045 1.694-.304a.12.12 0 0 1 .078.007c.045.02.07.074.056.122l-.032.09-.127.35zM15.662 39.621l-.155.43a.1.1 0 0 1-.027.047.1.1 0 0 1-.062.03l-.445.08-.59.106a.106.106 0 0 1-.11-.039l-.21-.25-.38-.452a.1.1 0 0 1-.022-.102.1.1 0 0 1 .082-.072l.168-.03q.889-.159 1.777-.32a.12.12 0 0 1 .078.008.1.1 0 0 1 .054.122q-.015.046-.033.092l-.125.349zM16.147 38.285l-.155.43a.107.107 0 0 1-.088.076l-.303.054-.733.132a.1.1 0 0 1-.057-.002.1.1 0 0 1-.055-.038l-.24-.287-.347-.412a.1.1 0 0 1-.028-.068.104.104 0 0 1 .086-.107l.107-.02 1.838-.33a.13.13 0 0 1 .07.005c.052.02.079.075.062.128q-.015.045-.032.09l-.125.348"
    ></path>
    <path
      fill="#fff"
      d="m58.584 39.764.36-.773-.228-.014c-.004 0-.011.005-.013.01l-.14.298-.576 1.245-.057.127.227.014q.009-.002.015-.011l.24-.523.072-.157.9.062-.319.692.228.015q.01-.002.014-.012.17-.372.342-.742a.114.114 0 0 0-.098-.166q-.473-.03-.945-.064zm1.963 1.084-.308.752.005.004.218.014q.007-.001.012-.01l.031-.075.277-.677a.163.163 0 0 0-.148-.226l-.418-.03h-.022l.01-.027.205-.467a.16.16 0 0 0-.139-.227l-.563-.04h-.02c.051.076.032.148-.01.22q.249.019.496.034l-.324.706zm.269.792.533-1.305-.233-.015-.54 1.304zm.448-1.693-.12.285.226.015c.004 0 .012-.008.014-.014l.097-.23.016-.04z"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_7465_1285"
        x1="32.919"
        x2="32.919"
        y1="27.93"
        y2="57.53"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24FAF5"></stop>
        <stop offset="1" stopColor="#2A9EF5"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_7465_1285"
        x1="58.143"
        x2="58.143"
        y1="35.953"
        y2="37.263"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24FAF5"></stop>
        <stop offset="1" stopColor="#2A9EF5"></stop>
      </linearGradient>
      <linearGradient
        id="paint2_linear_7465_1285"
        x1="59.943"
        x2="59.943"
        y1="25.039"
        y2="36.755"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#24FAF5"></stop>
        <stop offset="1" stopColor="#2A9EF5"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export default Motorcycle;
