import * as React from "react"

const PiggyBank = ({ size='80' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size * 1.275}
    fill="none"
    viewBox="0 0 80 102"
  >
    <g clipPath="url(#clip0_8255_761)">
      <path
        fill="#fff"
        d="M79.654 50.75a6.25 6.25 0 0 0-1.5-2.55 4.15 4.15 0 0 0-1.97-1.19c.37.585.705 1.26.955 1.98q.425 1.105.515 2.335a9 9 0 0 1 0 2.12 5 5 0 0 0-.71-1.46 6 6 0 0 0-2.4-1.915 4.7 4.7 0 0 0-5.975 1.91q-.375.615-.6 1.33c-5.91-9.84-16.545-16.355-28.705-16.355q-.93 0-1.845.05a41 41 0 0 0-11.155 1.565c-.31-2.17-3.16-6.57-16.26-6.57l4 12.55a26 26 0 0 0-6.235 7.325L.004 52s-.85 20 5 20h2.7a26.5 26.5 0 0 0 7.225 7.945l.075 12.05h5.4c6.55 0 9.6 0 9.6-3.75v-1.95c5.044.93 10.216.916 15.255-.04l-.255 1.99c0 3.75 3.1 3.75 9.7 3.75h5.3v-11.95a24 24 0 0 0 9.515-13.585c.285-.135.57-.12.86-.12q.434.001.86-.05c1.255-.23 2.4-.72 3.395-1.415a8.25 8.25 0 0 0 2.955-3.825q.21-.7.315-1.46l.005-.05a9.2 9.2 0 0 0 2.095-4.755c.2-1.38.077-2.786-.36-4.11zm-62.1 0a3.75 3.75 0 1 1 0 7.5 3.75 3.75 0 0 1 0-7.5M29.404 47a2.5 2.5 0 0 1-2.995-1.235 1.95 1.95 0 0 1 1.48-2.515 40 40 0 0 1 10.045-1.1l.505-.005q5.05.01 9.745 1.16c.63.12 1.31.91 1.31 1.86a2 2 0 0 1-.095.595 2.4 2.4 0 0 1-1.73 1.235h-.765a36 36 0 0 0-8.53-1h-.445c-3.025 0-5.965.365-8.78 1.055zm43.5 14.65a6 6 0 0 1-2.265.995l-.635.055v-2.25c.825.625 1.87 1 3 1h.105zm.4-3.4a2.2 2.2 0 0 1-2.295-1.24 3 3 0 0 1 .005-2.83 1.65 1.65 0 0 1 2.15-.48 2.4 2.4 0 0 1 1.54 2.24 7.5 7.5 0 0 1-.005 2.095 6.5 6.5 0 0 1-1.41.11z"
      ></path>
    </g>
    <g clipPath="url(#clip1_8255_761)">
      <circle cx="40.281" cy="15.289" r="13.125" fill="#fff"></circle>
      <path
        stroke="#1ECECA"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M38.414 27.79c-6.558 0-11.875-5.597-11.875-12.5 0-6.904 5.317-12.5 11.875-12.5S50.29 8.385 50.29 15.29c0 6.903-5.317 12.5-11.875 12.5"
      ></path>
      <path
        stroke="#1ECECA"
        strokeWidth="2.5"
        d="M37.781 2.79c5.416 0 16.25 1.25 16.25 12.5s-10.833 12.5-16.25 12.5"
      ></path>
      <path
        fill="#1ECECA"
        d="m36.388 19-1.692-2.916h-1.26V19H31.78v-8.4h3.36a2.79 2.79 0 0 1 2.796 2.784c0 1.056-.66 2.004-1.62 2.448L38.176 19zm-2.952-6.852v2.484h1.704c.624 0 1.14-.552 1.14-1.248s-.516-1.236-1.14-1.236zm9.135.684c1.608 0 2.928 1.38 2.928 3.168s-1.32 3.168-2.928 3.168c-.876 0-1.512-.324-1.944-.876V21.4H39.08V13h1.548v.708c.432-.552 1.068-.876 1.944-.876m-.288 4.86c.96 0 1.668-.684 1.668-1.692s-.708-1.692-1.668-1.692c-.948 0-1.656.684-1.656 1.692s.708 1.692 1.656 1.692"
      ></path>
    </g>
    <defs>
      <clipPath id="clip0_8255_761">
        <path fill="#fff" d="M0 22h80v80H0z"></path>
      </clipPath>
      <clipPath id="clip1_8255_761">
        <path fill="#fff" d="M25.281.29h30v30h-30z"></path>
      </clipPath>
    </defs>
  </svg>
)

export default PiggyBank
