import * as React from "react"

const ThreeCircles = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="45"
    fill="none"
    viewBox="0 0 55 55"
  >
    <path
      fill="#fff"
      d="M34.316 5.266a15.72 15.72 0 0 1 15.718 15.718c0 6.085-3.458 11.34-8.487 13.967a15.94 15.94 0 0 1-6.737 6.736 15.74 15.74 0 0 1-13.967 8.488A15.72 15.72 0 0 1 5.125 34.457c0-6.085 3.458-11.34 8.488-13.967a15.94 15.94 0 0 1 6.736-6.736 15.74 15.74 0 0 1 13.967-8.488M27.58 43.439A15.72 15.72 0 0 1 11.86 27.72a11.15 11.15 0 0 0-2.245 6.737 11.227 11.227 0 0 0 11.227 11.227c2.538 0 4.85-.83 6.737-2.245m6.736-6.737a15.72 15.72 0 0 1-15.718-15.718 11.15 11.15 0 0 0-2.246 6.736A11.227 11.227 0 0 0 27.58 38.948c2.537 0 4.85-.831 6.736-2.246m0-26.945c-2.537 0-4.85.83-6.736 2.245A15.72 15.72 0 0 1 43.298 27.72a11.15 11.15 0 0 0 2.245-6.736A11.227 11.227 0 0 0 34.316 9.757M23.09 20.984A11.227 11.227 0 0 0 34.316 32.21c1.347 0 2.627-.224 3.817-.673.45-1.19.674-2.47.674-3.818A11.227 11.227 0 0 0 27.58 16.493c-1.348 0-2.627.225-3.818.674-.449 1.19-.673 2.47-.673 3.817"
    ></path>
  </svg>
)

export default ThreeCircles
