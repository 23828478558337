import React, { useEffect } from 'react'
import { Footer, Header, HeaderB2B } from 'components'
import { ArrowRight } from 'assets/icons'
import { useNavigate } from 'react-router-dom'
import { useGetNewsFromCMS } from 'apis/query'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useQueryClient } from 'react-query'

const News = () => {
  const navigate = useNavigate()

  const { data: newsData, isLoading } = useGetNewsFromCMS()

  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.invalidateQueries('cms-news-list')
  }, [newsData])

  const spotlightPresent = newsData?.some(data => data.type === 'spotlight')

  // SPOTLIGHT
  const renderSpotlightNews = () => {
    return newsData?.filter(data => data.type === 'spotlight')?.map((item, index) => (
      <div key={index} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <div style={{ backgroundColor: '#F4F7FA' }}>
          <img loading='lazy'  alt='spotlight' src={item.image} style={{ width: '100%', height: '100%', borderRadius: '1.25rem', objectFit: 'cover', objectPosition: 'center' }} />
        </div>
        <div style={{
          backgroundColor: '#F4F7FA',
          borderRadius: '0 1.25rem 1.25rem 0',
          padding: '2.5rem 3rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '2.25rem' }}>
              {item.title}
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#414141' }}>
              {item.description}
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2.5rem' }}>
            <button
              onClick={() => navigate(`/news/detail/${item.slug}`, { state: { data: item } })}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
            >
              See News <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }

  // OTHER NEWS
  const renderOtherNews = () => {
    return newsData?.filter(data => data.type === 'other')?.map((item, index) => (
      <div key={index} style={{ display: 'flex', flexDirection: 'column' }}>
        <img loading='lazy' 
          alt={`news-${item.image}`}
          src={item.image}
          style={{
            width: '100%',
            height: '11.5rem',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: '0.65rem 0.65rem 0 0',
          }}
        />
        <div style={{
          backgroundColor: '#F2F6F9',
          padding: '1.25rem',
          borderRadius: '0 0 0.65rem 0.65rem',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '0.675rem',
        }}>
          <div className='two-lines' style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', color: '#414141' }}>
            {item.title}
          </div>
          <div className='three-lines' style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.75rem', color: '#414141' }}>
            {item.description}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '1rem' }}>
            <button
              onClick={() => navigate(`/news/detail/${item.slug}`, { state: { data: item } })}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.5rem 1rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
            >
              See News <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }

  return (
    <div>
      <Header />

      {/* SPOTLIGHT */}
      {spotlightPresent && (
        <div style={{ padding: '4rem 12.5rem' }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '3.5rem', marginBottom: '2rem' }}>
            Topik Hangat
          </div>
          <Spin spinning={isLoading} indicator={<LoadingOutlined spin />} size="large">
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '2rem' }}>
              {renderSpotlightNews()}
            </div>
          </Spin>
        </div>
      )}

      {/* OTHER NEWS */}
      <div style={{ padding: spotlightPresent ? '6rem 12.5rem' : '4rem 12.5rem 6rem 12.5rem' }}>
        {spotlightPresent && (
          <div style={{ width: '10rem', height: '1rem', backgroundColor: '#F04C3E', marginBottom: '2.5rem' }} />
        )}
        <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '3.5rem', marginBottom: spotlightPresent ? '3.75rem' : '2.75rem' }}>
          Berita Terbaru
        </div>
        <Spin spinning={isLoading} indicator={<LoadingOutlined spin />} size="large">
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '2rem' }}>
            {renderOtherNews()}
          </div>
        </Spin>
      </div>

      <Footer />
    </div>
  )
}

export default News
